import React, { useState } from "react";
import db from "../../../../db/index.json";
import IconCards from "../../../reusebale/iconCards";
import ParagraphTag from "../../../reusebale/Paragraph";
import ButtonTag from "../../../reusebale/button";
import Signature from "../createProperty/DigitalSignature"
import { File, CircleAlert } from "lucide-react";
import BaseUrl from "../../../../constants/BaseUrl";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import MyLoader from "../../../../component/reusebale/myLoader"
import * as LeaseAction from "../../../../store/actions/lease/LeaseAction";
import { useDispatch, } from "react-redux";
const LateFee = ({ isEsignature, formData, isDrawSignature, signatureObject, signatureLoader }) => {
  const [Funds, setFunds] = useState(db.properties.fundsData);
  const [signatureLoading, setSignatureLoading] = useState(false)
  const dispatch = useDispatch();

  const ConvertSignatureToUrl = (file) => {
    setSignatureLoading(true)
    const formdata = new FormData();
    formdata.append("image", file);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/uploads/upload`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.url) {

          ShowSuccess("Signature save successfully.")
          signatureObject(result)
          setSignatureLoading(false)
        } else {
          setSignatureLoading(false)
          ShowError("Oops! something went wrong")
        }
      })
      .catch((error) => console.error(error));
  }

  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="h-[10%] text-center">
        <ParagraphTag
          content="E-Signature"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        {/* <ParagraphTag
          content="Is your lease signed"
          classes="text-darkColor/50 font-popinsMedium text-xs"
        /> */}
        <button
          onClick={(e) => {
            e.preventDefault();

            alert(formData.isEsignature)
          }}
        >CLICK</button>
      </div>
      <div className="overflow-y-scroll h-[80%] panelScroll flex flex-col items-center pb-5">
        <div className="flex w-[40%] mx-auto justify-between mt-6">
          <div
            key={"Yes"}
            className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
            onClick={(e) => {
              e.preventDefault();

              isEsignature(true)
            }}
          >
            <IconCards
              mainClasses={`h-36 w-[130px] ${formData?.isEsignature ? "border-red-500 border-2" : ""
                }`}
              imgClasses="w-[40px]"
              path={
                "/assets/daashboard/create-propety/check.png"
              }
              title={"Yes"}
              titleClasses={
                "text-themeColor"
              }
            />
          </div>


          <div
            key={"No"}
            className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
            onClick={(e) => {
              e.preventDefault();
              isEsignature(false)
            }}
          >
            <IconCards
              mainClasses={`h-36 w-[130px] ${!formData?.isEsignature ? "border-red-500 border-2" : ""
                }`}
              imgClasses="w-[40px]"
              path={
                "/assets/daashboard/create-propety/close.png"
              }
              title={"No"}
              titleClasses={
                "text-themeColor"
              }
            />
          </div>

        </div>

        <div className="w-[40%] mx-auto  my-5">
          <div className="bg-black/10 h-[1px]"></div>
        </div>
        {formData?.isEsignature ? (
          <div className="text-center w-full">
            {/* <div class="flex justify-center items-center">
              <label class="flex items-center">
                <input
                  type="radio"
                  name="option"
                  value="option1"
                  onClick={() => {
                    isDrawSignature(true)

                  }}
                  checked
                  class="w-5 h-5 text-themeColor bg-gray-100 border-gray-300 focus:ring-themeColor"
                />
                <span class="text-darkColor font-popinsRegular text-sm pl-2">E Signature</span>
              </label>

              <label class="flex items-center ml-10">
                <input
                  type="radio"
                  name="option"
                  value="option2"
                  onClick={() => {
                    isDrawSignature(false)
                  }}
                  class="w-5 h-5 text-themeColor bg-gray-100 border-gray-300 focus:ring-themeColor"
                />
                <span class="text-darkColor font-popinsRegular text-sm pl-2">Upload</span>
              </label>
            </div> */}
            {formData?.drawSignature ? <div className="flex justify-center items-center ">
              {
                formData.eSignature ? <div>
                  <img
                    className="h-24 "
                    src={formData?.eSignature?.url}
                  />
                  <button
                    onClick={() => {
                      signatureObject(null)
                    }}
                    className="font-popinsSemiBold !text-sm mt-10"
                    style={{
                      marginRight: "10px",
                      padding: "10px 20px",
                      backgroundColor: "#E43B3B",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Change Signature
                  </button>
                </div> :
                  <Signature
                    signatureLoader={signatureLoading}
                    signatureFile={(sign) => {
                      ConvertSignatureToUrl(sign)
                    }}

                  />


              }


            </div> : <div className="flex justify-between w-[70%] mx-auto mt-6">


              {/* Enable image upload and trigger this function ConvertSignatureToUrl(sign) */}
              <div className="w-[49%] bg-[#F4F4F4] rounded-md flex flex-col items-center py-14 cursor-pointer">


                <File color="#B3B3B3" size={30} />
                <ParagraphTag
                  content="Upload Signature"
                  classes="text-[#B3B3B3] font-popinsMedium text-xs pt-1"
                />
              </div>
            </div>}
          </div>
        ) : (
          <div className="bg-[#F4F4F4] flex items-center w-[70%] mx-auto py-4 rounded-md my-3">
            <div className="w-[14%] flex justify-end pr-3">
              <CircleAlert color="#B3B3B3" size={35} />
            </div>
            <div className="w-[86%]">
              <ParagraphTag
                content="Always make sure your late fee policy is the same as in your actual lease agreement and that it is compliant with your state and local laws."
                classes="text-[#B3B3B3] font-popinsRegular text-xs"
              />
            </div>
          </div>
        )}
      </div>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={() => {
              dispatch(LeaseAction.ChangePropertyCreateSteps(6));
            }}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default LateFee;
