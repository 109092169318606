
import { CommonDataActionConst, ChangeMaintenenceStep, BroadcastFlowCreationActionConst, ChangeBroadcastStepActionConst } from "../../constants/DataActionConst"
const initialState = {
    loader: false,
    allData: [{ name: "musharaf" }],
    allCitiesAndStates: [],
    propertyTypes: [],
    maintenanceCategories: [],

    maintenanceStepCount: 1,

    boradcastStepCount: 1
}
export default (state = initialState, action) => {
    switch (action.type) {
        //Cities And States

        case CommonDataActionConst.GET_COMMON_MASTER_DATA_REQ:
            state = {
                ...state,
                loader: true,


            }
            break

        case CommonDataActionConst.GET_COMMON_MASTER_DATA_SUCCESS:
            state = {
                ...state,
                loader: false,
                allCitiesAndStates: action.result.citiesAndStates,
                propertyTypes: action.result.propertyTypes,
                maintenanceCategories: action.result.maintenanceCategories

            }
            break

        case CommonDataActionConst.GET_COMMON_MASTER_DATA_FAIL:
            state = {
                ...state,
                loader: false,

            }
            break


        case ChangeMaintenenceStep.CHANGE_MAINTENANCE_STEP:
            state = {
                ...state,
                maintenanceStepCount: action.step

            }
            break


        case ChangeBroadcastStepActionConst.CHANGE_BROADCAST_STEP:
            state = {
                ...state,
                boradcastStepCount: action.step

            }
            break




    }
    return state

}