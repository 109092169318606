export const CommonDataActionConst = {
    GET_COMMON_MASTER_DATA_REQ: "GET_COMMON_MASTER_DATA_REQ",
    GET_COMMON_MASTER_DATA_SUCCESS: "GET_COMMON_MASTER_DATA_SUCCESS",
    GET_COMMON_MASTER_DATA_FAIL: "GET_COMMON_MASTER_DATA_FAIL",
}

export const ChangeMaintenenceStep = {
    CHANGE_MAINTENANCE_STEP: "CHANGE_MAINTENANCE_STEP",

}



export const BroadcastFlowCreationActionConst = {
    CREATE_BROADCAST_REQ: "CREATE_BROADCAST_REQ",
    CREATE_BROADCAST_SUCC: "CREATE_BROADCAST_SUCC",
    CREATE_BROADCAST_FAIL: "CREATE_BROADCAST_FAIL"

}

export const ChangeBroadcastStepActionConst = {
    CHANGE_BROADCAST_STEP: "CHANGE_BROADCAST_STEP",

}

