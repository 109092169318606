import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph"
import ImageTag from "../../../reusebale/imageTag"

const TenantInfo = ({ leaseDetails }) => {
  return (
    <div className="bg-white rounded-xl">
      <div className="px-4 pt-4">
        <ParagraphTag
          content="Tenant's Personal Information"
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />
      </div>
      <div className="flex mt-1 items-center  pb-4 pl-4">

        <div className="w-[70%]">
          <div className="pt-3">
            <ParagraphTag
              content="Name"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={`${leaseDetails?.tenantInfo?.firstName}  ${leaseDetails?.tenantInfo?.lastName}`}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-2">
            <ParagraphTag
              content="Email Address"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={leaseDetails?.tenantInfo?.email}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-2">
            <ParagraphTag
              content="Phone"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={leaseDetails?.tenantInfo?.cellNo}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantInfo;
