import React, { useEffect, useState } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import ImageTag from "../../reusebale/imageTag";
import { Clock4, ArrowRight, CircleAlert } from "lucide-react";
import BaseUrl from "../../../constants/BaseUrl";
import { useSelector } from "react-redux";
import NoData from "../../reusebale/noData";
import Myloader from "../../reusebale/myLoader"

const MaintenanceRequestSection = ({ onViewDetails, onMaintainanceTable }) => {
  const { rightPanel } = db;

  const [allRequestes, setAllRequests] = useState([]);
  const { loginSuccess } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [loading, setloading] = useState(false);

  const GetMaintenenceRequest = () => {
    setloading(true)
    setLoader(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/maintenence/getMeintenanceRequestByLandlordId/${loginSuccess.user._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setLoader(false);
          setloading(false)
          setAllRequests(result.data);
        } else {
          setLoader(false);
          setloading(false)
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    GetMaintenenceRequest();
  }, []);

  return (
    <div className="bg-white rounded-2xl py-5 px-4 w-full mt-5 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="flex justify-between items-center ">
        <ParagraphTag
          content={rightPanel.requests}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <ParagraphTag
          content={rightPanel.viewall}
          classes="text-themeColor font-popinsSemiBold text-sm cursor-pointer"
          onTrigger={onMaintainanceTable}
        />
      </div>
      <div className="flex border-b-[1px] border-darkColor/40 pb-1 mt-6">
        <div className="w-[50%]">
          <ParagraphTag
            content={"Request Type"}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[25%] text-center ">
          <ParagraphTag
            content={rightPanel.status}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[25%] text-end">
          <ParagraphTag
            content={rightPanel.quick}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
      </div>
      <div className="h-[220px] overflow-y-scroll panelScroll">
        {allRequestes?.map((item, i) => (
          <div className="flex mt-4 justify-between">
            <div className="w-[49%] flex items-center">
              <div className="w-[16%]">
                <ImageTag
                  path="/assets/daashboard/setting-icon.png"
                  classes="w-full"
                  altText="login"
                />
              </div>
              <div className="w-[84%] pl-3">
                <ParagraphTag
                  content={item?.propertyInfo?.propertyName}
                  classes="text-darkColor/40 font-popinsMedium text-xs"
                />
                <ParagraphTag
                  content={item?.requestCategory?.category}
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
            <div className="w-[29%] flex items-center justify-center">
              {item?.status === "Parts Ordered" && (
                <Clock4 color="#1A1A1A" size={20} />
              )}
              {item?.status === "Overdue" && (
                <CircleAlert color="#1A1A1A" size={20} />
              )}
              <ParagraphTag
                content={item?.status}
                classes={`${
                  item?.status === "Completed"
                    ? "text-statusColor"
                    : "text-black"
                } font-popinsMedium text-sm pl-2`}
              />
            </div>
            <div className="w-[19%] flex justify-center items-center">
              <div
                className="flex justify-center items-center bg-themeColor w-[35%] h-8 rounded-lg cursor-pointer"
                onClick={() => {
                  onViewDetails(item);
                }}
              >
                <ArrowRight color="#FFFF" size={20} strokeWidth={2} />
              </div>
            </div>
          </div>
        ))}
        {loading && (
          <div className="flex justify-center items-center w-full mt-20">
            <Myloader />
          </div>
        )}
        {(allRequestes.length === 0 && !loading) && (
          <div className="w-full mt-5 flex justify-center">
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
};

export default MaintenanceRequestSection;
