import React, { useState, useEffect } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import ButtonTag from "../../../reusebale/button";
import { Bed, Bath, Ruler } from "lucide-react";
import db from "../../../../db/index.json";
import PropertySuccessPopup from "../../../popup/propertySuccess";
import { Trash } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import MyLoader from "../../../reusebale/myLoader";
import * as PropertyAction from "../../../../store/actions/property/PropertyAction";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import UnitsCard from "../../../reusebale/uniCard";
import EditUnits from "../../../popup/editUnits";
const PropertyOverview = ({ onClose }) => {
  const dispatch = useDispatch();
  const {
    createNewPropertyBody,
    loader,
    createNewPropertySucc,
    createNewPropertyError,
    isUpdate,
    updatePropertySucc,
    updatePropertyError
  } = useSelector((state) => state.property);
  const { loginSuccess } = useSelector((state) => state.auth);
  const { properties } = db;

  const [showSuccess, setShowSuccess] = useState(false);
  console.log(updatePropertySucc, "updatePropertySucc")

  const openPopup = () => {
    setShowSuccess(true);
  };

  const [showUnitPopUp, setShowUnitpopUp] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState({ "id": 0, "unitName": "", "totalBuilding": "", "rent": "", "rentType": "", "offices": "", "bathrooms": "", "size": "", "buildingDepth": "", "clearHeight": "", "parkingRation": "", "sprinklers": "", "water": "", "electric": "", "waltRollArea": "", "numberOfSuites": "", "lodingDocks": "", "gradeLevelDoors": "", "tenantSize": "", "yearBuild": "", "hvac": false, "hvacValue": "" })


  const closePopup = () => {
    setShowSuccess(false);
    dispatch(PropertyAction.ClearAllPropertyCreateStatus());
    onClose();
  };

  useEffect(() => {
    if (createNewPropertySucc) {
      setShowSuccess(true);
      dispatch(PropertyAction.ClearAllPropertyCreateStatus());
    } else {
      ShowError(createNewPropertyError?.message);
    }
  }, [createNewPropertySucc, createNewPropertyError]);


  useEffect(() => {
    if (updatePropertySucc) {
      setShowSuccess(true);
      dispatch(PropertyAction.ClearAllPropertyCreateStatus());
    } else {
      ShowError(updatePropertyError?.message);
    }
  }, [updatePropertySucc, updatePropertyError]);

  return (
    <div className="h-full">
      <div
        onClick={(e) => {
          e.preventDefault();
          console.log(JSON.stringify(createNewPropertyBody));
        }}
        className="text-center h-[8%]"
      >
        <ParagraphTag
          content="Property Overview"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
      </div>
      <div className="h-[82%] w-full overflow-y-scroll panelScroll">
        <div className="flex justify-center">
          <div className="w-[75%] py-5">
            <div className="flex justify-between py-2">
              <div className="w-[64%]">
                <div className="flex mt-3">
                  <div className="w-[50%] pr-1">
                    <ParagraphTag
                      content="Property Name"
                      classes={`text-darkColor font-popinsSemiBold text-sm `}
                    />
                    <ParagraphTag
                      content={createNewPropertyBody?.propertyName}
                      classes={`text-darkColor/50 font-popinsRegular text-xs pt-[2px]`}
                    />
                  </div>
                  <div className="w-[50%] pl-1">
                    <ParagraphTag
                      content="Property Type"
                      classes={`text-darkColor font-popinsSemiBold text-sm `}
                    />

                    <ParagraphTag
                      content={`${createNewPropertyBody?.category} ( ${createNewPropertyBody?.type} )`}
                      classes={`text-darkColor/50 font-popinsRegular text-xs pt-[2px]`}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <ParagraphTag
                    content="Complete Address"
                    classes={`text-darkColor font-popinsSemiBold text-sm `}
                  />

                  <ParagraphTag
                    content={createNewPropertyBody?.completeAddress}
                    classes={`text-darkColor/50 font-popinsRegular text-xs pt-[2px]`}
                  />
                </div>

                <div className="flex mt-3">
                  <div className="w-[50%] pr-1">
                    <ParagraphTag
                      content="Street 1"
                      classes={`text-darkColor font-popinsSemiBold text-sm `}
                    />
                    <ParagraphTag
                      content={createNewPropertyBody?.streetOne}
                      classes={`text-darkColor/50 font-popinsRegular text-xs pt-[2px]`}
                    />
                  </div>
                  <div className="w-[50%] pl-1">
                    <ParagraphTag
                      content="Street 2"
                      classes={`text-darkColor font-popinsSemiBold text-sm `}
                    />

                    <ParagraphTag
                      content={createNewPropertyBody?.streetTwo}
                      classes={`text-darkColor/50 font-popinsRegular text-xs pt-[2px]`}
                    />
                  </div>
                </div>
                <div className="flex mt-3">
                  <div className="w-[50%]">
                    <ParagraphTag
                      content="City"
                      classes={`text-darkColor font-popinsSemiBold text-sm `}
                    />

                    <ParagraphTag
                      content={createNewPropertyBody?.city}
                      classes={`text-darkColor/50 font-popinsRegular text-xs pt-[2px]`}
                    />
                  </div>
                  <div className="w-[50%]">
                    <ParagraphTag
                      content="Country"
                      classes={`text-darkColor font-popinsSemiBold text-sm `}
                    />

                    <ParagraphTag
                      content={createNewPropertyBody?.country}
                      classes={`text-darkColor/50 font-popinsRegular text-xs pt-[2px]`}
                    />
                  </div>
                </div>
                <div className="flex mt-3">
                  <div className="w-[50%]">
                    <ParagraphTag
                      content="State"
                      classes={`text-darkColor font-popinsSemiBold text-sm `}
                    />

                    <ParagraphTag
                      content={createNewPropertyBody?.state}
                      classes={`text-darkColor/50 font-popinsRegular text-xs pt-[2px]`}
                    />
                  </div>
                  <div className="w-[50%]">
                    <ParagraphTag
                      content="Zip Code"
                      classes={`text-darkColor font-popinsSemiBold text-sm `}
                    />

                    <ParagraphTag
                      content={createNewPropertyBody?.zipCode}
                      classes={`text-darkColor/50 font-popinsRegular text-xs pt-[2px]`}
                    />
                  </div>
                </div>
              </div>
              <div className="w-[35%] h-[270px] mt-2">
                <ImageTag
                  classes="rounded-md w-full h-full object-cover"
                  path={createNewPropertyBody?.thumbnailImage?.url}
                  altText="logo"
                />
              </div>
            </div>
            <div className="flex flex-wrap">
              {createNewPropertyBody.images.map((i) => {
                return (
                  <div className="w-[20%] h-[150px] relative bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
                    <img
                      src={i?.imageUrl}
                      alt=""
                      className="rounded-md object-cover"
                    />
                    <div className="absolute">
                      <div className="bg-dangerColor w-8 h-8 rounded-md flex justify-center items-center cursor-pointer">
                        <Trash color="#FFFF" size={18} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-2">
              <ImageTag
                classes="rounded-lg"
                path={"/assets/daashboard/map-overview.png"}
                altText="logo"
              />
            </div>
          </div>
        </div>

        <div className="bg-white py-5 rounded-xl">
          <div className="w-[95%] mx-auto pt-5 ">
            <div className="flex justify-between">
              <ParagraphTag
                content="Units"
                classes={`text-darkColor font-popinsSemiBold text-lg `}
              />
            </div>
            <div className="flex flex-wrap justify-between mt-3">
              {createNewPropertyBody?.unitData?.map((item, i) => (
                <UnitsCard
                  onTrigger={() => {
                    setSelectedUnit(item)
                    setShowUnitpopUp(true)
                  }}
                  data={item}
                  classes="w-[49%] my-2 py-3 px-2 cursor-pointer "
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[10%] flex justify-center items-center">
        {loader ? (
          <div className="flex justify-center items-center">
            <MyLoader />
          </div>
        ) : (
          <div className="w-[40%] mx-auto">

            <ButtonTag
              name={isUpdate ? "Update" : "Submit"}
              classes="text-base bg-themeColor text-center text-white"
              onSubmit={(e) => {
                e.preventDefault();
                if (!createNewPropertyBody.propertyName) {
                  ShowError("Property address information is missing")
                } else if (createNewPropertyBody.unitData.length === 0) {
                  ShowError("Please create atleast one unit")
                } else {
                  if (isUpdate) {
                    const finalArray = {
                      ...createNewPropertyBody,
                      unitCount: createNewPropertyBody.unitData.length
                    }
                    dispatch(PropertyAction.UpdatePropertyAction(finalArray))
                    // console.log(JSON.stringify(finalArray))
                  } else {

                    dispatch(
                      PropertyAction.CreateNewPropertyAction(
                        loginSuccess?.user?._id,
                        createNewPropertyBody
                      )
                    );
                  }

                }


                // loginSuccess
              }}
            />
          </div>
        )}
      </div>
      {
        showUnitPopUp && <EditUnits
          selectedUnit={selectedUnit}

          onBack={() => {
            setShowUnitpopUp(false)
          }}
          unitNameValue={selectedUnit.unitName}
          buildingValue={selectedUnit?.totalBuilding}
          rentValue={selectedUnit?.rent}
          rentTypeValue={selectedUnit.rentType}
          officesValue={selectedUnit.offices}
          bathroomsValue={selectedUnit.bathrooms}
          sizeValue={selectedUnit.size}
          buildingDepthValue={selectedUnit.buildingDepth}
          clearHeightValue={selectedUnit.clearHeight}
          parkingRationValue={selectedUnit.parkingRation}
          sprinklersValue={selectedUnit.sprinklers}
          waterValue={selectedUnit.water}
          electricValue={selectedUnit.electric}
          waltRollAreaValue={selectedUnit.waltRollArea}
          noOfSuitsValue={selectedUnit.numberOfSuites}
          loadingDocksValue={selectedUnit.lodingDocks}
          gradeLevelDoorsValue={selectedUnit.gradeLevelDoors}
          yearBuildValue={selectedUnit.yearBuild}
          tenantsSizeValue={selectedUnit.tenantSize}
          hvacValue={selectedUnit.hvac}
          hvacNumberValue={selectedUnit.hvacValue}
        />
      }
      {showSuccess && <PropertySuccessPopup succMessage={`Property ${isUpdate ? "Updated" : "Created"} successfully`} onClose={closePopup} />}
    </div>
  );
};

export default PropertyOverview;
