import React, {useState} from "react";
import LabelTag from "../reusebale/label";
import { Link } from "react-router-dom";
import { Eye } from "lucide-react";
import ButtonTag from "../reusebale/button";
import BaseUrl from "../../constants/BaseUrl"
import { ShowError, ShowSuccess } from "../../utils/ToasFunction";
import { useSelector, useDispatch } from "react-redux"
import MyLoader from "../reusebale/myLoader";
import * as AuthAction from "../../store/actions/auth/AuthAction"
import { useNavigate } from "react-router-dom";
const ResetFormSection = () => {
    const { loginSuccess } = useSelector(
        (state) => state.auth
    );
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [formFields, setFormFields] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    })


    const ChangePasswordHandler = (e) => {
        e.preventDefault();
        if (formFields.currentPassword === "") {
            ShowError("Current password cannot be empty")
            return
        } else if (formFields.newPassword === "") {
            ShowError("New password cannot be empty")
            return
        } else if (formFields.confirmPassword === "") {
            ShowError("Confirm New password cannot be empty")
            return
        } else if (formFields.newPassword != formFields.confirmPassword) {
            ShowError("New Password and Confirm New Password must match.")
            return
        }
        setLoader(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "id": loginSuccess?.user?._id,
            "currentPassword": formFields.currentPassword,
            "newPassword": formFields.newPassword
        });

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${BaseUrl}landlord/auth/changePassword`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setLoader(false)
                    ShowSuccess(result.message)
                    setFormFields({
                        currentPassword: "",
                        newPassword: "",
                        confirmPassword: ""
                    })
                    dispatch(AuthAction.LogoutAction())

                    navigate("/login", { replace: true });
                } else {
                    setLoader(false)
                    ShowError(result.message)
                }
            })
            .catch((error) => console.error(error));
    }

  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const togglePasswordVisibility3 = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };

    return (
        <form className="mt-8">
            <div className="w-full">
                <LabelTag name='Current Password' />
                <div className="relative">
                    <input
                        value={formFields.currentPassword}
                        onChange={(e) => {
                            setFormFields({
                                ...formFields,
                                currentPassword: e.target.value
                            })
                        }}
                        type={showPassword ? "text" : "password"}
                        placeholder="Current password here.."
                        className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 font-popinsRegular"
                    />
                    <div

                        title="Show password"
                        className="absolute right-3 top-3 text-stone-950/40 cursor-pointer"
                        onClick={togglePasswordVisibility}
                    >
                        <Eye size={20} />
                    </div>
                </div>
            </div>
            <div className="w-full pt-4 lg:mt-0">
                <LabelTag name='New Password' />
                <div className="relative">
                    <input
                        value={formFields.newPassword}
                        onChange={(e) => {
                            setFormFields({
                                ...formFields,
                                newPassword: e.target.value
                            })
                        }}
                        type={showPassword2 ? "text" : "password"}
                        placeholder="New password here.."
                        className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 font-popinsRegular"
                    />
                    <div
                        title="Show password"
                        className="absolute right-3 top-3 text-stone-950/40 cursor-pointer"
                        onClick={togglePasswordVisibility2}
                    >
                        <Eye size={20} />
                    </div>
                </div>
            </div>

            <div className="w-full pt-4 lg:mt-0">
                <LabelTag name='Confirm New Password' />
                <div className="relative">
                    <input
                        value={formFields.confirmPassword}
                        onChange={(e) => {
                            setFormFields({
                                ...formFields,
                                confirmPassword: e.target.value
                            })
                        }}
                        type={confirmShowPassword ? "text" : "password"}
                        placeholder="Confirm new password here.."
                        className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 font-popinsRegular"
                    />
                    <div
                        title="Show password"
                        className="absolute right-3 top-3 text-stone-950/40 cursor-pointer"
                        onClick={togglePasswordVisibility3}
                    >
                        <Eye size={20} />
                    </div>
                </div>
            </div>
            <div to={"/login"}>
                {
                    loader ? <div className="flex justify-center items-center mt-4">
                        <MyLoader />
                    </div> : <ButtonTag
                        onSubmit={ChangePasswordHandler}
                        name='Submit' classes=' mt-5 text-base text-center text-white bg-themeColor text-themeColor mb-8 lg:mb-0' />
                }

            </div>
        </form>
    );
};

export default ResetFormSection;
