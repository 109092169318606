import React, { useEffect, useState } from "react";
// import db from '../../db/index.json'
import LeftSection from "../../component/dashboard-section/panels/left-panel"
import RightSection from "../../component/dashboard-section/panels/right-panel"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import * as AuthAction from "../../store/actions/auth/AuthAction"
import * as DataAction from "../../store/actions/data/DataAction"
import MainSection from "../../component/dashboard-section/profile-complete/main"
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState("Home");
  const { loginSuccess, profileCompleted, userCompleteProfile } = useSelector((state) => state.auth)
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const handleItemClick = (componentName) => {
    if (componentName === "Logout") {
      dispatch(AuthAction.LogoutAction())
      navigate("/login", { replace: true });
      return
    }


    setActiveComponent(componentName); // Update active component
  };



  useEffect(() => {
    if (loginSuccess) {
      dispatch(AuthAction.GetLandlordProfile(loginSuccess?.user?._id))
      dispatch(DataAction.GetAllMasterData())
    }

  }, [loginSuccess])







  return (
    <div className="flex justify-center items-center w-[98%] mx-auto h-screen">
      {
        userCompleteProfile?.approved && <>
          <div className="w-[20%] h-[97vh]">
            <LeftSection onItemClick={handleItemClick} />
          </div>
          <div className="w-[80%] pl-6 h-screen overflow-scroll scrollbar-hide panelScroll">

            <RightSection activeComponent={activeComponent} setActiveComponent={setActiveComponent} />
          </div>
        </>
      }
      {
        userCompleteProfile && !userCompleteProfile?.approved && <div className="w-[90%] mx-auto">
          <MainSection />
        </div>
      }


    </div>
  );
}

export default Dashboard;
