import React from "react";
import db from "../../../../db/index.json";
import ParagraphTag from "../../../reusebale/Paragraph";

const RentBreakDown = ({ leaseDetails }) => {
  const { lease } = db;

  function calculateTotalAmount(list) {
    if (!Array.isArray(list)) {
      throw new Error("Input must be an array of objects");
    }

    const total = list.reduce((sum, item) => {
      if (typeof item.amount !== "string") {
        throw new Error(`Invalid amount format for item: ${JSON.stringify(item)}`);
      }
      const amount = parseFloat(item.amount);
      if (isNaN(amount)) {
        throw new Error(`Invalid amount value for item: ${JSON.stringify(item)}`);
      }
      return sum + amount;
    }, 0);

    return total;
  }

  return (
    <div className="h-full">
      <div className="w-[95%] mx-auto">
        <ParagraphTag
          content="Rent Breakdown"
          classes="text-darkColor font-popinsSemiBold text-lg pt-4 text-center"
        />

        {
          leaseDetails?.additionalCharges?.map((i) => {
            return (
              <div className="flex pt-5 justify-between">
                <div className="w-[50%]">
                  <ParagraphTag
                    content={i.title}
                    classes="text-darkColor font-popinsSemiBold text-xs"
                  />
                  <ParagraphTag
                    content={i.description}
                    classes="text-darkColor/40 font-popinsRegular text-xs line-clamp-1"
                  />
                </div>
                <div className="w-[50%] flex justify-end items-center">
                  <ParagraphTag
                    content={`$ ${i.amount}`}
                    classes="text-darkColor font-popinsSemiBold text-sm"
                  />
                </div>
              </div>
            )
          })
        }




        <div className="h-[1px] bg-darkColor/10 w-full mt-28"></div>
        <div className="flex py-5 justify-between">
          <div className="w-[50%]">
            <ParagraphTag
              content="Total Amount"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
          </div>

          <div className="w-[50%] flex justify-end items-center">
            <ParagraphTag
              content={`$ ${calculateTotalAmount(leaseDetails?.additionalCharges ? leaseDetails?.additionalCharges : [])}`}
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentBreakDown;
