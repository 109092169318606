import React from "react";
import ImageTag from "../../reusebale/imageTag";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import InfoCards from "../../reusebale/cards";
import { useSelector } from "react-redux"
const ProfileInfo = ({ onBack, onAction }) => {
  const { userCompleteProfile, } = useSelector((state) => state.auth);
  const { home } = db;
  
  return (
    <div>
      <div className="mt-3 flex justify-between">
        <div className="w-[49%] bg-white rounded-xl">
          <div className="px-4 pt-4">
            <ParagraphTag
              content="Personal Information"
              classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
            />
          </div>
          <div className="flex mt-1 items-center">
            <div className="w-[30%]">
              <ImageTag
                path={userCompleteProfile?.personalInformation?.profileImage}
                classes="w-[220px] rounded-full p-3"
                altText="logo"
              />
            </div>
            <div className="w-[70%]">
              <div className="pt-3">
                <ParagraphTag
                  content="Name"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
                <ParagraphTag
                  content={`${userCompleteProfile?.personalInformation?.firstName} ${userCompleteProfile?.personalInformation?.lastName}`}
                  classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                />
              </div>
              <div className="pt-3">
                <ParagraphTag
                  content="Email Address"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
                <ParagraphTag
                  content={userCompleteProfile?.personalInformation?.email}
                  classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                />
              </div>
              <div className="pt-2">
                <ParagraphTag
                  content="Cell Number"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
                <ParagraphTag
                  content={userCompleteProfile?.personalInformation?.phoneNumber}
                  classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-[49%] bg-white rounded-xl">
          <div className="px-4 pt-4">
            <ParagraphTag
              content="Company Info"
              classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
            />
          </div>
          <div className="flex mt-1 items-center">
            <div className="w-[30%]">
              <ImageTag
                path={userCompleteProfile?.companyInformation?.companyLogo}
                classes="w-[230px] rounded-full"
                altText="logo"
              />
            </div>
            <div className="w-[70%] px-2">
              <div className="pt-3">
                <ParagraphTag
                  content="Complete Address"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
                <ParagraphTag
                  content={userCompleteProfile?.companyInformation?.completeAddress}
                  classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                />
              </div>
              <div className="pt-2 flex">
                <div className="w-[50%]">
                  <ParagraphTag
                    content="City"
                    classes="text-darkColor font-popinsSemiBold text-sm"
                  />
                  <ParagraphTag
                    content={userCompleteProfile?.companyInformation?.city}
                    classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                  />
                </div>
                <div className="w-[50%]">
                  <ParagraphTag
                    content="Country"
                    classes="text-darkColor font-popinsSemiBold text-sm"
                  />
                  <ParagraphTag
                    content={userCompleteProfile?.companyInformation?.country}
                    classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                  />
                </div>
              </div>
              <div className="pt-2 flex">
                <div className="w-[50%]">
                  <ParagraphTag
                    content="State"
                    classes="text-darkColor font-popinsSemiBold text-sm"
                  />
                  <ParagraphTag
                    content={userCompleteProfile?.companyInformation?.state}
                    classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                  />
                </div>
                <div className="w-[50%]">
                  <ParagraphTag
                    content="Zip Code"
                    classes="text-darkColor font-popinsSemiBold text-sm"
                  />
                  <ParagraphTag
                    content={userCompleteProfile?.companyInformation?.zipCode}
                    classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mt-5" id="gk-cards">
        {home.cardData2.map((item, i) => (
          <InfoCards item={item} onTrigger={onAction}/>
        ))}
      </div>
    </div>
  );
};

export default ProfileInfo;
