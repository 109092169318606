import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";

const CompanyInfo = ({ data }) => {




  return (
    <div className="bg-white rounded-xl pb-2">
      <div className="px-4 pt-4">
        <ParagraphTag
          content="Tenant's Company Information"
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />
      </div>
      <div className="flex mt-1">
        <div className="w-[30%] flex justify-center pt-4">
          <ImageTag
            path={data?.companyInformation?.companyLogo}
            classes="w-[170px] h-[170px] rounded-full object-cover"
            altText="logo"
          />
        </div>
        <div className="w-[70%] px-2">
          <div className="pt-3">
            <ParagraphTag
              content="Company Name"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={data?.companyInformation?.companyName}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-3">
            <ParagraphTag
              content="Company Address"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={data?.companyInformation?.completeAddress}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-2 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="Street One"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={data?.companyInformation?.street1}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="Street Two"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={data?.companyInformation?.street2}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>

          </div>


          <div className="pt-2 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="State"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={data?.companyInformation?.state}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="City"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={data?.companyInformation?.city}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>

          </div>

          <div className="pt-2 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="Country"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={data?.companyInformation?.state}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="Website"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={data?.companyInformation?.companyWebsite}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
