import React from "react";
import db from "../../db/index.json";
import UnitsCard from "../reusebale/uniCard";
import { Search } from "lucide-react"

const AddUnitPopup = ({ onClose, allUnits, onSelectUnit }) => {
  const { properties } = db;
  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[40%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col h-[80vh] ">
          <div className="h-[15%] w-[90%] mx-auto flex-col justify-between flex">
            <p className="text-darkColor font-popinsSemiBold text-base text-center">
              Add Units
            </p>
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Search"
                className="w-full font-popinsRegular mt-1 bg-[#E6BF9F4D] pl-4 py-[10px] text-xs rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
              <div className="absolute top-[16px] right-4">
                <Search color="#1A1A1A5D" size={15} />
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-3 h-[80%] overflow-y-scroll w-[90%] mx-auto panelScroll">
            {allUnits?.map((item, i) => (
              <div className="w-full">
                <UnitsCard
                  onTrigger={() => {
                    onSelectUnit(item)
                  }}
                  data={item} classes="w-full my-2 py-3 px-2" />
              </div>
            ))}
          </div>
          <div className="h-[5%] pt-3">
            <p className="cursor-pointer font-popinsMedium text-darkColor text-center text-sm" onClick={onClose}>Close</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddUnitPopup;
