import React, { useEffect, useState } from "react";
import InfoSection from "../tenantsDetail/info";
import LeaseSection from "../tenantsDetail/leaseSection";
import MaintainanceSection from "../tenantsDetail/maintainance";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import InfoCards from "../../../reusebale/cards";
import db from "../../../../db/index.json";
import BaseUrl from "../../../../constants/BaseUrl";
import CompanyInfoSection from "./companyInfo"
import { useSelector } from "react-redux"

const MainTenantSection = ({ onBack, seletedTenant, onViewMaintainDetails, onViewMaintainCreate, toggleLeaseDetail, toggleCreateLease, onTriggerCard }) => {
  const { tenants } = db;
  const [tenantProfileInfo, setTenantProfileInfor] = useState();
  const [maintenanceRequest, setMaintenancRequest] = useState([]);
  const { loginSuccess } = useSelector((state) => state.auth)
  const GetTenantProfileInformation = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}tenant/profile/getProfileDetails/${seletedTenant?._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          setTenantProfileInfor(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const GetMaintenanceRequetByTenantId = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/maintenence/getMeintenanceRequestByTenantId/${seletedTenant?._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setMaintenancRequest(result.data);
        }
      })
      .catch((error) => console.error(error));
  };


  const [AllLease, setAllLeases] = useState([])
  const GetAllleasesByLandloardAndTenantId = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/lease/getAllLeaseByLandlordAndTenant/${loginSuccess.user._id}/${seletedTenant?._id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllLeases(result.data)
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    GetTenantProfileInformation();
    GetMaintenanceRequetByTenantId();
    GetAllleasesByLandloardAndTenantId()
  }, []);


  //Hassand here you will get all the avilable leases
  // AllLease


  return (
    <div className="mt-6">
      <Breadcrumbs
        classes={`text-darkColor font-popinsSemiBold text-xl`}
        content={`${seletedTenant?.firstName} ${seletedTenant?.lastName}`}
        subheading="Tenant Information"
        onBack={onBack}
      />

      <div className="flex justify-between">
        <div className="w-[49%]">
          <InfoSection tenantInfo={tenantProfileInfo} />
        </div>
        <div className="w-[49%]">
          <CompanyInfoSection CompanyInfo={tenantProfileInfo} />
        </div>
      </div>

      <LeaseSection AllLeases={AllLease} isViewDetails={toggleLeaseDetail} toggleCreateLease={toggleCreateLease}/>
      <MaintainanceSection maintenanceRequest={maintenanceRequest} onViewDetails={onViewMaintainDetails} onViewCreate={onViewMaintainCreate} />
      <div className="flex flex-wrap my-5" id="gk-cards">
        {tenants.cardData2.map((item, i) => (
          <InfoCards item={item} classes="mr-[2%]" onTrigger={onTriggerCard} />
        ))}
      </div>
    </div>
  );
};

export default MainTenantSection;
