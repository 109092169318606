import React, { useEffect, useState, useRef } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import { Camera, Trash } from "lucide-react";
import { ChevronDown } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import BaseUrl from "../../../../constants/BaseUrl";
import AddPropertyPopup from "../../../popup/addPropertyPopup";
import AddUnitPopup from "../../../popup/unitpopup";
import * as DataAction from "../../../../store/actions/data/DataAction"
import moment from "moment";
import { ShowError } from "../../../../utils/ToasFunction";
const Details = ({
  formField,
  onPropertySelect,
  onUnitSelect,
  onDescriptionChange,
  onCategorySelect,
  onDueDateChange,
  onTypeSelect,
  imageList
}) => {
  const selectedProperty = formField?.propertyInfo?.propertyName;
  const selectedUnit = formField?.unitInfo?.unitName;
  const MaintenanceCategories = useSelector(
    (state) => state.data.maintenanceCategories
  );

  const dispatch = useDispatch();

  const [allProperties, setAllProperties] = useState([]);
  const [allUnits, setAllUnits] = useState([]);

  const [showUnitPopUp, setShowUnitpopUp] = useState(false);
  const [showPropertyPopUp, setshowPropertypopUp] = useState(false);

  const { loginSuccess } = useSelector((state) => state.auth);

  const fileInputRef = useRef(null);

  const handleUpload = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const [images, setImages] = useState([])


  const UploadImagesToServer = (imagesList) => {
    const formdata = new FormData();
    imagesList.forEach((image, index) => {
      formdata.append(`images`, image);
    });
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(`${BaseUrl}api/uploads/upload-multiple`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.files) {
          imageList(result.files)
        } else {
          ShowError(
            "Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB"
          );
        }
      })
      .catch((error) => {
        ShowError(
          "Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB"
        );
      });
  };
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      UploadImagesToServer(files)
      // console.log("Selected file:", files);
      // Further actions for the uploaded file
    }
  };

  const GetAllProperties = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/property/getAllPropertiesbyLandlord/${loginSuccess?.user?._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllProperties(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const GetUnitByPropertyId = (id) => {
    const requestOptions = {
      method: "GET",

      redirect: "follow",
    };

    fetch(`${BaseUrl}api/property/getUnitByPropertyId/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          let availableUnits = [];
          const unit = result?.unitData?.unitData;
          for (const key in unit) {
            if (unit[key].occupied == true) {
              availableUnits.push(unit[key]);
            }
          }

          setAllUnits(availableUnits);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    GetAllProperties();
  }, []);

  const openPopup = () => {
    setShowUnitpopUp(true);
  };

  const closePopup = () => {
    setShowUnitpopUp(false);
  };

  const openPropertyPopup = () => {
    setshowPropertypopUp(true);
  };

  const closePropertyPopup = () => {
    setshowPropertypopUp(false);
  };

  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Request Details"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <ParagraphTag
          content="Complete the request details"
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className=" w-[70%] mx-auto">
          <div className="flex justify-between mt-6">
            <div className="w-[49%]">
              <LabelTag
                name="Property"
                isStaric={false}
                classes="text-darkColor text-left !text-xs !font-popinsMedium"
              />
              <div
                className="w-full bg-bgColor py-[10px] rounded-md text-sm px-3 cursor-pointer flex justify-between"
                onClick={openPropertyPopup}
              >
                <p className="font-popinsRegular text-darkColor/40">
                  {selectedProperty}
                </p>
                <ChevronDown color="#1A1A1A2D" size={18} />
              </div>
            </div>
            <div className="w-[49%]">
              <LabelTag
                name="Unit"
                isStaric={false}
                classes="text-darkColor !text-left !text-xs !font-popinsMedium"
              />
              <div
                className="w-full bg-bgColor py-[10px] rounded-md text-sm px-3 cursor-pointer flex justify-between"
                onClick={openPopup}
              >
                <p className="font-popinsRegular text-darkColor/40">
                  {selectedUnit}
                </p>
                <ChevronDown color="#1A1A1A2D" size={18} />
              </div>
            </div>
          </div>



          {/* Category / type */}
          <div className="flex justify-between pt-2">
            <div className="w-[49%]">
              <LabelTag
                isStaric={false}
                name="Request Category"
                classes="!text-xs !font-popinsMedium"
              />
              <select
                onChange={onCategorySelect}
                value={formField?.requestCategory?.category}
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              >
                {MaintenanceCategories.map((i) => {
                  return <option value={i.category}>{i.category}</option>;
                })}
              </select>
            </div>

            <div className="w-[49%]">
              <LabelTag
                isStaric={false}
                name="Request Date"
                classes="!text-xs !font-popinsMedium"
              />
              <input
                disabled
                type="date"
                placeholder="Please select end date"
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                onChange={(event) => {
                  // Handle the date change here
                  // onEndDate(event.target.value);
                }}
                value={moment(new Date(formField.requestDate)).format(
                  "YYYY-MM-DD"
                )} // Corrected format
              />
            </div>



          </div>

          {/* Descriotion */}
          <div className="pt-2">
            <LabelTag
              isStaric={false}
              name="Description"
              classes="!text-xs !font-popinsMedium"
            />
            <textarea
              onChange={onDescriptionChange}
              value={formField?.description}
              type="text"
              cols={10}
              placeholder="Please enter your description"
              className="w-full h-20 mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            ></textarea>
          </div>

         
          <div className="pt-4 flex">
            <div
              className="border-dashed border-2 border-[#B3B3B3] text-center flex flex-col items-center justify-center w-[100px] h-[100px] bg-slate-100 rounded-md cursor-pointer"
              onClick={handleUpload}
            >
              <input
                type="file"
                multiple
                accept="image/*"
                ref={fileInputRef} // Attach ref to the input
                onChange={handleImageChange}
                style={{ display: "none" }} // Hide the input field
              />
              <Camera className="text-darkColor/50" size={35} strokeWidth={2} />
              <ParagraphTag
                content="Upload Thumbnail"
                classes="pt-1 text-darkColor/50 font-popinsRegular text-xs"
              />
            </div>

            <div className="flex">

              {
                formField?.imageList?.map((i) => {
                  return (
                    <div className="w-[100px] h-[100px] mx-3 bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
                      <img
                        src={i?.imageUrl}

                      />
                      {/* <div className="border-2 border-dangerColor w-8 h-8 rounded-md flex justify-center items-center">
                  <Trash color="#E43B3B" size={18} />
                </div> */}
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[40%] mx-auto">
          <ButtonTag
            onSubmit={() => {
              dispatch(DataAction.ChangeMaintenanceCurrentStep(2))
            }}


            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>

      {showUnitPopUp && (
        <AddUnitPopup
          allUnits={allUnits}
          onClose={closePopup}
          onSelectUnit={(item) => {
            onUnitSelect(item);
            closePopup();
            // alert(JSON.stringify(on))
          }}
        />
      )}

      {showPropertyPopUp && (
        <AddPropertyPopup
          onSelect={(item) => {
            onPropertySelect(item);
            GetUnitByPropertyId(item._id);
          }}
          allProperties={allProperties}
          onClose={closePropertyPopup}
        />
      )}
    </div>
  );
};

export default Details;
