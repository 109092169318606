import React, { useEffect } from "react";
import './App.css';
import { useLocation, } from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";
import dataReducer from "./store/reducers/data/dataReducer.js";
import authReducer from "./store/reducers/auth/AuthReducer.js";
import propertyReducer from "./store/reducers/property/PropertyReducer.js";
import LeaseReducer from "./store/reducers/lease/LeaseReducer.js";

import { ToastContainer } from 'react-toastify';
import { combineReducers, createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import ReduxThunk from 'redux-thunk';

import MainRoutes from "./Routes/MainRoutes.js";

function App() {
  // const location = useLocation();
  // useEffect(() => {
  //   window.scroll(0, 0);
  // }, [location])

  //Redux Setup
  const rootReducer = combineReducers({
    data: dataReducer,
    auth: authReducer,
    property: propertyReducer,
    lease: LeaseReducer,


  })


  const Store = createStore(rootReducer, applyMiddleware(ReduxThunk))

  return (
    <Provider store={Store}>
      <MainRoutes />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Provider>
  );
}

export default App;
