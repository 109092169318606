import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import AddTenantPopup from '../../../popup/tenantPopup'
import { CircleAlert, ChevronDown } from "lucide-react";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import { useSelector, useDispatch } from "react-redux"
import * as LeaseAction from "../../../../store/actions/lease/LeaseAction"
const Tenants = ({
  onFirstNameChange,
  onLastNameChange,
  onEmailAddressChange,
  onCellNumberChange,
  formData
}) => {
  const dispatch = useDispatch()
  const [showTenantPopUp, setShowTenantpopUp] = useState(false);
  const { leaseData, leaseUpdate } = useSelector((state) => state.lease)





  const NextHandler = (e) => {
    e.preventDefault();
    dispatch(LeaseAction.ChangePropertyCreateSteps(3))


  }


  const closePopup = () => {
    setShowTenantpopUp(false);
  };


  return (
    <div className="w-[40%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Tenant Information"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Please fill in the tenant information. Make sure to enter the correct details, as this information cannot be updated in the future."
          classes="text-darkColor/50 font-popinsMedium text-xs"
        />
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll flex flex-col items-center px-1">
        {/* <div className="w-full">
          <LabelTag

            name="Tenant"
            isStaric={false}
            className="text-darkColor text-left !text-xs !font-popinsMedium"
            classes="!text-xs !font-popinsMedium"
          />
          <div
            className="w-full bg-bgColor py-[10px] rounded-md text-sm px-3 cursor-pointer flex justify-between"
            onClick={openPopup}
          >
            <p className="font-popinsRegular text-darkColor/40">Unit# 14</p>
            <ChevronDown color="#1A1A1A2D" size={18} />
          </div>
        </div> */}
        <div className="h-[1px] w-full bg-darkColor/30 my-3"></div>
        <div className="w-full">
          <LabelTag
            isStaric={false}
            name="Tenant’s First Name"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            disabled={leaseUpdate}
            value={formData.firstName}
            onChange={onFirstNameChange}
            type="text"
            placeholder="Enter tenant's first name here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="w-full mt-3">
          <LabelTag

            isStaric={false}
            name="Tenant’s Last Name"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            disabled={leaseUpdate}
            value={formData.lastName}
            onChange={onLastNameChange}
            type="text"
            placeholder="Enter tenant's last name here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="w-full mt-3">
          <LabelTag
            isStaric={false}
            name="Tenant’s Email Address"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            disabled={leaseUpdate}
            value={formData.email}
            onChange={onEmailAddressChange}
            type="text"
            placeholder="Enter tenant's email here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>

        <div className="w-full mt-3">
          <LabelTag
            isStaric={false}
            name="Tenant’s Cell Number"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            disabled={leaseUpdate}
            value={formData.cellNo}
            onChange={onCellNumberChange}
            type="text"
            placeholder="Enter tenant's cell number here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="w-full mt-3">
          <LabelTag
            isStaric={false}
            name="Tenant’s Password"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            disabled={leaseUpdate}
            value={formData.password}
            type="password"
            placeholder="Enter tenant's cell password here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="bg-[#F4F4F4] flex w-full py-4 rounded-md my-3">
          <div className="w-[15%] flex justify-center">
            <CircleAlert color="#B3B3B3" size={35} />
          </div>
          <div className="w-[85%]">
            <ParagraphTag
              content="The tenant will be provided a temporary system generated password. "
              classes="text-[#B3B3B3] font-popinsRegular text-xs w-[85%]"
            />
          </div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={NextHandler}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
      {showTenantPopUp && <AddTenantPopup onClose={closePopup} />}
    </div>
  );
};

export default Tenants;
