import React, { useState } from "react";
import ButtonTag from "../../../reusebale/button";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import PropertySuccessPopup from "../../../popup/propertySuccess";
import db from "../../../../db/index.json";
import Myloader from "../../../reusebale/myLoader";
import { useSelector, useDispatch } from "react-redux";
import BaseUrl from "../../../../constants/BaseUrl";
import * as DataAction from "../../../../store/actions/data/DataAction";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";

const Overview = ({ formFields, onCrateSuccess, onBackHome }) => {
  const { broadcasts } = db;
  const dispatch = useDispatch();
  const { loginSuccess } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const CreateRequestHandler = () => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      landlordId: loginSuccess.user._id,
      title: formFields.title,
      description: formFields.description,
      tenantList: formFields.tenantList,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}api/broadcast/createBroadCast`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          setLoader(false);
          onCrateSuccess();
          setShowSuccess(true);
        } else {
          setLoader(false);
          ShowError("Oops! Something went wrong. Please try again later.");
        }
      })
      .catch((error) => console.error(error));
  };

  const closePopup = () => {
    setShowSuccess(false);
    onBackHome()
    // dispatch(DataAction.ChangeBroadcastCurrentStep(1))
  };

  return (
    <div className="w-[95%] mx-auto h-full">
      <div className="text-center h-[10%] flex flex-col justify-center">
        <ParagraphTag
          content="Overview"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <ParagraphTag
          content="Please ensure all information is accurate before creating the request."
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <div className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className="text-center flex flex-col justify-center">
          <ParagraphTag
            content="Title"
            classes="text-darkColor font-popinsSemiBold text-base"
          />
          <div>
            <ParagraphTag
              content={formFields?.title}
              classes="text-[#686868] font-popinsRegular text-xs"
            />
          </div>
        </div>
        <div className="text-center flex flex-col justify-center w-[90%] mx-auto pt-3">
          <ParagraphTag
            content="Description"
            classes="text-darkColor font-popinsSemiBold text-base"
          />
          <div>
            <ParagraphTag
              content="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec tincidunt molestie, massa nunc varius arcu, at scelerisque elit erat a magna. Donec quis erat at libero ultrices mollis. In hac habitasse platea dictumst. Vivamus vehicula leo dui, at porta nisi facilisis finibus. In euismod augue vitae nisi ultricies, non aliquet urna tincidunt. Integer in nisi eget nulla commodo faucibus efficitur quis massa. Praesent felis est, finibus et nisi ac, hendrerit venenatis libero. Donec consectetur faucibus ipsum id gravida."
              classes="text-[#686868] font-popinsRegular text-xs line-clamp-3 pt-1"
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full mt-6" id="gk-cards">
          {formFields?.tenantList?.map((item) => {
            return (
              <div className="w-[23%] flex mr-[2%] mt-1">
                <div className="text-center pt-2 px-1 ">
                  <ParagraphTag
                    content={`TEN-${item?._id?.slice(0, 10)}`}
                    classes="font-popinsRegular text-xs text-[#686868]"
                  />
                  <ParagraphTag
                    content={`${item?.firstName} ${item?.lastName}`}
                    classes="text-darkColor font-popinsSemiBold text-sm"
                  />
                  <div className="flex justify-between w-full pt-1">
                    <div className="flex w-[49%] items-center">
                      <input
                        checked={item?.emailSelect}
                        disabled
                        type="checkbox"
                        className="w-3 h-3 bg-bgColor rounded-sm cursor-pointer"
                      />
                      <ParagraphTag
                        content="email"
                        classes="text-[#686868] text-xs pl-1 font-popinsRegular"
                      />
                    </div>
                    <div className="flex w-[49%] items-center justify-end">
                      <input
                        checked={item?.numberSelect}
                        disabled
                        type="checkbox"
                        className="w-3 h-3 bg-bgColor rounded-sm cursor-pointer"
                      />
                      <ParagraphTag
                        content="SMS"
                        classes="text-[#686868] text-xs pl-1 font-popinsRegular"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="h-[10%] flex justify-center">
        <div className="w-[50%]">
          {loader ? (
            <div className="flex justify-center items-center">
              <Myloader />
            </div>
          ) : (
            <ButtonTag
              onSubmit={() => {
                if (formFields.title === "") {
                  ShowError("Title is required");
                } else if (formFields.description === "") {
                  ShowError("Description is required");
                } else if (formFields.tenantList.length === 0) {
                  ShowError("Tenant is required");
                } else {
                  CreateRequestHandler();
                }
              }}
              name="Submit"
              classes="text-base text-center bg-themeColor text-white"
            />
          )}
        </div>
      </div>
      {showSuccess && (
        <PropertySuccessPopup
          succMessage={`Broadcast request created successfully`}
          onClose={closePopup}
        />
      )}
    </div>
  );
};

export default Overview;
