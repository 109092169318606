import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import * as DataAction from "../../../../store/actions/data/DataAction"
import { useDispatch, useSelector } from "react-redux";
const CollerInfo = ({ formField, onCollarNameChange, onCollarNumberChange, onCollarEmailChange }) => {
  const dispatch = useDispatch();
  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[15%] flex flex-col justify-center">
        <ParagraphTag
          content="Caller Info"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <div>
          <ParagraphTag
            content="In case of urgency, please call the person directly"
            classes="text-[#686868] font-popinsRegular text-xs"
          />
        </div>
      </div>
      <form className="overflow-y-scroll h-[75%] panelScroll px-1">
        <div className=" w-[40%] mx-auto">
          <div className="mt-3">
            <LabelTag isStaric={false} name="Caller Name" classes="!text-xs !font-popinsMedium" />
            <input

              value={formField.callerName}
              onChange={(e) => {
                onCollarNameChange(e)
              }}
              type="text"
              placeholder="Please enter caller name here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="mt-2">
            <LabelTag isStaric={false} name="Caller Phone" classes="!text-xs !font-popinsMedium" />
            <input
              value={formField.callerPhone}
              onChange={(e) => {
                onCollarNumberChange(e)
              }}
              type="text"
              placeholder="Please enter caller phone number here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="mt-2">
            <LabelTag isStaric={false} name="Caller Email" classes="!text-xs !font-popinsMedium" />
            <input
              value={formField.callerEmail}
              onChange={(e) => {
                onCollarEmailChange(e)
              }}
              type="text"
              placeholder="Please enter caller email here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[40%] mx-auto">
          <ButtonTag
            onSubmit={() => {
              dispatch(DataAction.ChangeMaintenanceCurrentStep(3))
            }}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default CollerInfo;
