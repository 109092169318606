import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";
import ReactPaginate from "react-paginate";
import NoData from "../../reusebale/noData";
import Myloader from "../../reusebale/myLoader";
import {
  Plus,
  ArrowUpDown,
  ArrowDownUp,
  Clock4,
  CircleAlert,
  CircleCheck,
  ArrowRight,
  User,
} from "lucide-react";
import db from "../../../db/index.json";
import BaseUrl from "../../../constants/BaseUrl";
import { useSelector } from "react-redux";
import moment from "moment";
const LeaseTable = ({ onViewDetails, onViewCreate }) => {
  const { maintainances } = db;
  const { loader, loginSuccess, loginError } = useSelector(
    (state) => state.auth
  );

  const [allRequest, setAllRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const GetAllRequest = (id) => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/maintenence/getMeintenanceRequestByLandlordId/${loginSuccess.user._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllRequest(result.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    GetAllRequest();
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  // const paginatedData = data.slice(
  //   currentPage * itemsPerPage,
  //   (currentPage + 1) * itemsPerPage
  // );

  const handlePageClick = (selectedPage, id) => {
    setCurrentPage(selectedPage.selected, id);
  };

  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "lease",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [..."data"].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    // setData(sortedData);
  };

  return (
    <div className="h-full pt-5">
      <div className="bg-white w-full h-[87%]">
        <div className="w-[95%] mx-auto h-full">
          <div className="flex justify-end items-center h-[12%]">
            <div className="flex justify-end w-[40%]">
              <button
                className="w-[48%] bg-themeColor rounded-md flex items-center justify-center h-9"
                onClick={onViewCreate}
              >
                <Plus color="#FFFFFF" size={15} />
                <ParagraphTag
                  content={maintainances.new}
                  classes="text-white font-popinsSemiBold text-xs pl-2"
                />
              </button>
            </div>
          </div>
          <div className="overflow-x-scroll w-full h-[88%] panelScroll pt-2">
            <table className="w-full ">
              <thead className="sticky top-0 bg-white">
                <tr className="border-b border-black/20">
                  <th className="w-[25%] text-left pb-2">
                    <span className="flex">
                      <ParagraphTag
                        content={maintainances.thead1}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "lease" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("lease")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("lease")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[12%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={maintainances.thead2}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "status" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[22%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={maintainances.thead3}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "related" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("related")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("related")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("related")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[15%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={maintainances.thead7}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                      />
                      {sortConfig.key === "date" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("date")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("date")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("date")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[13%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={maintainances.thead4}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                      />
                      {sortConfig.key === "date" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("date")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("date")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("date")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[13%] text-right pr-4 pb-2">
                    <ParagraphTag
                      content={maintainances.thead6}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {allRequest.map((item) => (
                  <tr
                    key={item?._id}
                    className="hover:bg-gray-100 rounded-md cursor-pointer"
                  >
                    <td className="py-2">
                      <div className="flex items-center justify-between">
                        <div className="w-[18%] rounded-full">
                          <ImageTag
                            path="/assets/daashboard/setting-icon.png"
                            classes="w-full"
                            altText="logo"
                          />
                        </div>
                        <div
                          className="w-[78%] cursor-pointer"

                        >
                          <ParagraphTag
                            content={`MAIN-${item?._id?.slice(0, 10)}`}
                            classes="text-darkColor/50 font-popinsMedium text-xs"
                          />
                          <ParagraphTag
                            content={`${item?.requestCategory?.category} `}
                            classes="text-darkColor font-popinsSemiBold text-sm"
                          />
                        </div>
                      </div>
                    </td>
                    <td className="py-2 font-popinsMedium text-darkColor text-sm">
                      <div className="flex justify-center items-center">
                        <span>
                          {(() => {
                            switch (item.status) {
                              case "Active":
                                return (
                                  <CircleAlert color="#E43B3B" size={20} />
                                );
                              case "Parts Ordered":
                                return <Clock4 color={"#FF814E"} size={20} />;
                              case "Completed":
                                return (
                                  <CircleCheck color="#01CB78" size={20} />
                                );
                              default:
                                break;
                            }
                          })()}
                        </span>
                        <span
                          className={`pl-2 ${item.status === "Parts Ordered"
                            ? "text-themeColor"
                            : item.status === "In Progress"
                              ? "text-lightgray"
                              : "text-[#01CB78]"
                            }`}
                        >
                          {item.status}
                        </span>
                      </div>
                    </td>
                    <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                      <div className="flex flex-col">
                        <span className="text-darkColor/50 text-xs">
                          {`PROP-${item?.propertyId?.slice(0, 10)}`}
                        </span>
                        <span className="text-sm text-darkColor">
                          {item?.propertyInfo?.propertyName}
                        </span>
                      </div>
                    </td>
                    <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                      {item.unitInfo.unitName}
                    </td>
                    <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                      {moment(item?.dueDate).format("DD/MM/YYYY")}
                    </td>
                    <td className="pt-5 2xl:pt-4 px-4 flex justify-center items-center h-full">
                      <div onClick={() => {
                        onViewDetails(item);
                      }} className="flex justify-center items-center border border-themeColor w-7 h-7 rounded-md cursor-pointer">
                        <ArrowRight color="#6F6F6F" size={18} strokeWidth={2} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loading && (
              <div className="flex justify-center items-center w-full mt-20">
                <Myloader />
              </div>
            )}
            {(allRequest.length === 0 && !loading) && (
              <div className="w-full mt-5">
                <NoData />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center h-[13%]">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          // pageCount={Math.ceil(data.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName="flex space-x-2 items-center"
          pageClassName="px-3 py-1 border rounded-md text-sm font-popinsMedium cursor-pointer"
          previousClassName="px-3 py-1 border rounded-l-md text-sm cursor-pointer font-popinsMedium"
          nextClassName="px-3 py-1 border rounded-r-md text-sm font-popinsMedium cursor-pointer font-popinsMedium"
          disabledClassName="opacity-50 !cursor-not-allowed"
          activeClassName="bg-themeColor text-white"
          breakClassName="px-3 py-1 text-sm font-popinsMedium"
        />
      </div>
    </div>
  );
};

export default LeaseTable;
