import React from "react";


const ParagraphTag=(props) => {
  const { content, classes, onTrigger } = props;

  return (
    <h1 className={`${classes}`} onClick={onTrigger}>{content}</h1>
  );
}

export default ParagraphTag;
