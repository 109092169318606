import { CommonDataActionConst, ChangeMaintenenceStep, ChangeBroadcastStepActionConst } from "../../constants/DataActionConst"
import BaseUrl from "../../../constants/BaseUrl";



export const ChangeMaintenanceCurrentStep = (step) => {
    return async dispatch => {
        dispatch({
            type: ChangeMaintenenceStep.CHANGE_MAINTENANCE_STEP,
            step: step
        })
    }
}


export const GetAllMasterData = () => {

    return async dispatch => {
        dispatch({
            type: CommonDataActionConst.GET_COMMON_MASTER_DATA_REQ
        })

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(`${BaseUrl}api/master/commonData`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "Success") {
                    let propertyData = result.commonMasterData.propertyTypes;
                    for (const key in propertyData) {
                        if (propertyData[key].category === "Residential") {
                            propertyData[key]["activeImage"] = "resedential-color.png";
                            propertyData[key]["inActiveImage"] = "resedential.png";
                            let typeLIst = propertyData[key].types;
                            for (const i in typeLIst) {
                                if (typeLIst[i].name === "Single") {
                                    typeLIst[i]["imagePath"] = "single.png";
                                    typeLIst[i]["imagePathActive"] = "single-color.png";
                                } else if (typeLIst[i].name === "Multi Family") {
                                    typeLIst[i]["imagePath"] = "family.png";
                                    typeLIst[i]["imagePathActive"] = "family-color.png";
                                } else if (typeLIst[i].name === "Condo") {
                                    typeLIst[i]["imagePath"] = "condo.png";
                                    typeLIst[i]["imagePathActive"] = "condo-color.png";
                                } else if (typeLIst[i].name === "Town Home") {
                                    typeLIst[i]["imagePath"] = "town.png";
                                    typeLIst[i]["imagePathActive"] = "town-color.png";
                                } else {
                                    typeLIst[i]["imagePath"] = "other.png";
                                    typeLIst[i]["imagePathActive"] = "other-color.png";
                                }
                            }
                        } else {
                            propertyData[key]["activeImage"] = "commercial-color.png";
                            propertyData[key]["inActiveImage"] = "commercial.png";
                            let typeLIst = propertyData[key].types;
                            for (const i in typeLIst) {
                                if (typeLIst[i].name === "Flex category") {
                                    typeLIst[i]["imagePath"] = "office.png";
                                    typeLIst[i]["imagePathActive"] = "office-color.png";
                                } else if (typeLIst[i].name === "Retail Space") {
                                    typeLIst[i]["imagePath"] = "reatilSpace.png";
                                    typeLIst[i]["imagePathActive"] = "reatilSpace-color.png";
                                } else if (typeLIst[i].name === "Industrial") {
                                    typeLIst[i]["imagePath"] = "industry.png";
                                    typeLIst[i]["imagePathActive"] = "industry-color.png";
                                } else if (typeLIst[i].name === "Warehouse") {
                                    typeLIst[i]["imagePath"] = "warehouse.png";
                                    typeLIst[i]["imagePathActive"] = "warehouse-color.png";
                                } else if (typeLIst[i].name === "Hospitality") {
                                    typeLIst[i]["imagePath"] = "hospitality.png";
                                    typeLIst[i]["imagePathActive"] = "hospitality-color.png";
                                } else {
                                    typeLIst[i]["imagePath"] = "other.png";
                                    typeLIst[i]["imagePathActive"] = "other-color.png";
                                }
                            }
                        }
                    }

                    dispatch({
                        type: CommonDataActionConst.GET_COMMON_MASTER_DATA_SUCCESS,
                        result: result.commonMasterData,
                        propertyData: propertyData
                    })
                } else {
                    // alert("ERROR=", JSON.stringify(result))
                    dispatch({
                        type: CommonDataActionConst.GET_COMMON_MASTER_DATA_FAIL,

                    })
                }
            })
            .catch((error) => console.error(error));

        return



    }
}


export const ChangeBroadcastCurrentStep = (step) => {
    return async dispatch => {
        dispatch({
            type: ChangeBroadcastStepActionConst.CHANGE_BROADCAST_STEP,
            step: step
        })
    }
}