import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import db from "../../db/index.json";
import LabelTag from "../reusebale/label";
import ParagraphTag from "../reusebale/Paragraph";
import ButtonTag from "../reusebale/button";
import { EyeOff, Eye } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { ShowError } from "../../utils/ToasFunction";
import MyLoader from "../reusebale/myLoader";
import * as AuthAction from "../../store/actions/auth/AuthAction";
const FormSection = () => {
  const { loader, loginSuccess, loginError } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({
    email: "",
    password: "",
  });
  const { login } = db;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const SaveDataToLocal = (data) => {
    localStorage.setItem("loginSuccess", JSON.stringify(data));
  };
  useEffect(() => {
    if (loginSuccess) {
      SaveDataToLocal(loginSuccess);
      setFormFields({
        ...formFields,
        email: "",
        password: "",
      });
      navigate("/dashboard");
    } else if (loginError?.data?.email) {
      navigate("/account-verification");
    } else {
      ShowError(loginError?.message);
    }
  }, [loginSuccess, loginError]);

  const loginHandler = (e) => {
    e.preventDefault();
    if (formFields.email === "") {
      ShowError("Please enter your email address first");
    } else if (formFields.password === "") {
      ShowError("Please enter your password first");
    } else {
      dispatch(AuthAction.UserLogInAction(formFields));
    }
  };

  return (
    <form>
      <div class="w-full mt-3">
        <LabelTag name={login.emailLabel} classes="!text-xs" />
        <input
          value={formFields.email}
          onChange={(e) => {
            setFormFields({
              ...formFields,
              email: e.target.value,
            });
          }}
          type="text"
          placeholder="Enter your email.."
          className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
        />
      </div>
      <div className="w-full mt-3">
        <LabelTag name={login.passLabel} classes="!text-xs" />
        <div className="relative">
          <input
            value={formFields.password}
            onChange={(e) => {
              setFormFields({
                ...formFields,
                password: e.target.value,
              });
            }}
            type={showPassword ? "text" : "password"}
            placeholder="Password here.."
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
          />
          <div
            title="Show password"
            className="absolute right-3 top-3 text-stone-950/40 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <Eye size={20} /> : <EyeOff size={20} />}
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-3 ">
        <div className="flex w-[50%]">
          <input
            type="checkbox"
            className="w-4 bg-bgColor rounded-sm cursor-pointer"
          />
          <ParagraphTag
            content={login.remember}
            classes="text-[#686868] text-xs pl-3 font-popinsRegular"
          />
        </div>
        <Link to={"/forget-password"} className="w-[50%] flex justify-end">
          <ParagraphTag
            content={login.forgetPass}
            classes="text-themeColor text-xs font-popinsMedium cursor-pointer"
          />
        </Link>
      </div>
      {/* {
        loader ? <div className="flex justify-center items-center">
          <MyLoader />
        </div> : <ButtonTag onSubmit={loginHandler} name={login.SubmitBtn} classes='text-base bg-themeColor hover:bg-themeColor/90 mt-3 text-center  text-white' />
      } */}
      <button
        onClick={loginHandler}
        className="text-base bg-themeColor hover:bg-themeColor/90 mt-3 text-center  text-white cursor-pointer font-popinsMedium rounded-md flex justify-center mx-auto py-2 w-full items-center"
      >
        <span>{login.SubmitBtn}</span>
        {loader && (
          <div role="status" className="pl-3">
            <svg
              aria-hidden="true"
              class="w-7 h-7 text-gray-200 animate-spin dark:text-white fill-themeColor"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        )}
      </button>

      <div className="flex items-center my-2">
        <div className="w-[15%] lg:w-[30%] 2xl:w-[35%] bg-[#1A1A1A]/30 h-[1px]"></div>
        <div className="w-[70%] lg:w-[40%] 2xl:w-[30%] flex justify-center">
          <ParagraphTag
            content={login.account}
            classes="text-[#686868] font-popinsRegular text-xs"
          />
        </div>
        <div className="w-[15%] lg:w-[30%] 2xl:w-[35%] bg-[#1A1A1A]/30 h-[1px]"></div>
      </div>
      <Link to={"/signup"}>
        <ButtonTag
          name={login.signUpBtn}
          classes="text-base text-center border-2 border-themeColor text-themeColor mb-8 lg:mb-0"
        />
      </Link>
    </form>
  );
};

export default FormSection;
