import React from "react";
import ParagraphTag from "../reusebale/Paragraph";
import ImageTag from "../reusebale/imageTag";
import { ArrowRight } from "lucide-react";

const InfoCards = (props) => {
  const { item, classes, onTrigger } = props;

  return (
    <div className={`w-[32%] my-2 mr-[2%] h-[68px] flex pl-4 items-center bg-white rounded-md ${classes}`}>
      <div className="w-[80%] flex items-center">
        <ImageTag classes={`w-[45px] `} path={item.image} altText="logo" />
        <div className="pl-3">
          <ParagraphTag
            content={item.title}
            classes={`text-darkColor font-popinsSemiBold text-sm `}
          />
          <ParagraphTag
            content={item.desc}
            classes={`text-[#1A1A1A]/40 font-popinsMedium text-xs `}
          />
        </div>
      </div>
      <div className="w-[20%] h-full flex justify-end items-end">
        <div className="flex justify-center items-center bg-themeColor w-8 h-8 rounded-md cursor-pointer" onClick={() => {onTrigger(item.title)}}>
          <ArrowRight className="text-white" size={18} strokeWidth={2} />
        </div>
      </div>
    </div>
  );
};

export default InfoCards;
