import React from 'react'
import ImageTag from "../reusebale/imageTag"
import ParagraphTag from "../reusebale/Paragraph"

const NoData = () => {
    return (
        <div className='flex flex-col items-center w-full justify-center mt-10'>
            <ImageTag path='/assets/daashboard/nodata.png' classes='w-[50px]' altText='login' />
            <ParagraphTag content='No Data Found' classes='text-darkColor text-base pt-2 font-popinsRegular' />
        </div>
    )
}

export default NoData