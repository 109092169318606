import React, { useEffect, useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import Terms from "./terms";
import Tenants from "./tenants";
import RentCharges from "./rentCharges";
import Deposite from "./deposite";
import Esignature from "./lateFee";
import Overview from "./overview";
import { useSelector, useDispatch } from "react-redux"
import * as LeaseAction from "../../../../store/actions/lease/LeaseAction"
const CreateMain = ({ onBack }) => {
  const steps = ["Terms", "Tenant", "Rent", "Deposit", "Signature", "Overview"];
  const dispatch = useDispatch()
  const { leaseCreateStep } = useSelector((state) => state.lease)
  const { leaseData, leaseUpdate } = useSelector((state) => state.lease)

  const { loader, loginSuccess, loginError } = useSelector((state) => state.auth)
  const handleStepClick = (step) => {
    dispatch(LeaseAction.ChangePropertyCreateSteps(step))
    // setleaseCreateStep(step);
  };

  function generatePassword() {
    const length = 10;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    return password;
  }

  const [formFields, setFormFields] = useState({
    propertyInfo: null,
    unitInfo: null,
    leaseTerm: "",
    startDate: null,
    endDate: null,


    rentStartDate: null,
    rentFrequency: "",
    chargeLate: false,
    latePercentage: "",
    graceDays: "",
    additionalCharges: [],


    securityDeposit: false,
    lastDayToSubmitDeposit: null,
    securityDepositAmount: 0,


    isEsignature: false,
    eSignature: null,
    drawSignature: true

  })

  useEffect(() => {
    if (leaseUpdate) {
      setFormFields({
        ...formFields,
        propertyInfo: leaseData.propertyInfo,
        unitInfo: leaseData.unitInfo,
        leaseTerm: leaseData.leaseTerm,
        startDate: leaseData.startDate,
        endDate: leaseData.endDate,
        rentStartDate: leaseData.rentStartDate,
        rentFrequency: leaseData.rentFrequency,
        chargeLate: leaseData.chargeLate,
        latePercentage: leaseData.latePercentage,
        graceDays: leaseData.graceDays,
        additionalCharges: leaseData.additionalCharges,
        securityDeposit: leaseData.securityDeposit,
        securityDepositAmount: leaseData?.securityDepositAmount ? leaseData?.securityDepositAmount : 0,
        lastDayToSubmitDeposit: leaseUpdate?.lastDayToSubmitDeposit ? leaseUpdate?.lastDayToSubmitDeposit : null,
        isEsignature: leaseData.isEsignature,
        eSignature: leaseData.eSignature,


      })

      setTenantInfo({
        ...tenentInfo,
        firstName: leaseData?.tenantInfo?.firstName,
        lastName: leaseData?.tenantInfo?.firstName,
        email: leaseData?.tenantInfo?.email,
        cellNo: leaseData?.tenantInfo?.cellNo,
        password: leaseData?.tenantInfo?.password,




      })
    }
  }, [leaseUpdate])



  const [tenentInfo, setTenantInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    cellNo: "",
    landlordId: loginSuccess?.user?._id,
    // password: generatePassword()
    password: "112233"
  })




  return (
    <div className="h-screen">
      <div className="h-[10vh] flex flex-col justify-center">
        <ParagraphTag
          content="Leases"
          classes="text-darkColor font-popinsSemiBold text-2xl"
        />
        <ParagraphTag
          content="Renew or end leases that are expiring soon."
          classes="text-[#686868] font-popinsMedium text-sm"
        />
      </div>

      <div className="h-[90vh] py-3 ">
        <div className="bg-white rounded-md h-full">
          <div className="w-full mx-auto h-[15%]">
            {/* Stepper */}
            <div className="relative w-[70%] mx-auto flex items-center justify-between pt-5">
              {/* Step Circles */}
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center w-full relative"
                >
                  {/* Circle */}
                  <div
                    className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${leaseCreateStep === index + 1
                      ? "border-orange-500 bg-white text-orange-500"
                      : leaseCreateStep > index + 1
                        ? "border-orange-500 bg-orange-500 text-white"
                        : "border-gray-300 bg-white text-gray-500"
                      } font-popinsMedium cursor-pointer transition-all`}
                    onClick={() => handleStepClick(index + 1)}
                  >
                    {index + 1}
                  </div>

                  {/* Step Label */}
                  <p
                    className={`mt-1 text-xs font-popinsRegular text-[#686868] ${leaseCreateStep >= index + 1
                      ? "text-orange-500"
                      : "text-gray-500"
                      }`}
                  >
                    {step}
                  </p>

                  {/* Line Connector */}
                  {index < steps.length - 1 && (
                    <div
                      className={`absolute top-4 left-[calc(50%+16px)] right-[calc(-50%+16px)] h-0.5 ${leaseCreateStep > index + 1
                        ? "bg-orange-500"
                        : "bg-gray-300"
                        }`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* <button
            onClick={() => {
              alert(JSON.stringify(formFields))
            }}
          >CLICK</button> */}

          <div className="h-[85%]">
            {leaseCreateStep === 1 && <Terms
              selectedUnitTerm={formFields.leaseTerm}
              selectedProperty={formFields?.propertyInfo?.propertyName ? formFields?.propertyInfo?.propertyName : "Please select a property"}
              selectedUnit={formFields?.unitInfo?.unitName ? formFields?.unitInfo?.unitName : "Please select a unit"}
              formData={formFields}

              onUnitSelect={(item) => {
                setFormFields({
                  ...formFields,
                  unitInfo: item
                })
              }}
              onPropertySelect={(item) => {
                setFormFields({
                  ...formFields,
                  propertyInfo: item
                })
              }}

              onLeaseterm={(term) => {
                setFormFields({
                  ...formFields,
                  leaseTerm: term
                })
              }}
              onStartDate={(date) => {
                setFormFields({
                  ...formFields,
                  startDate: date
                })
              }}

              onEndDate={(date) => {
                setFormFields({
                  ...formFields,
                  endDate: date
                })
              }}


            />}

            {leaseCreateStep === 2 && <Tenants
              onFirstNameChange={(e) => {
                setTenantInfo({
                  ...tenentInfo,
                  firstName: e.target.value
                })
              }}

              onLastNameChange={(e) => {
                setTenantInfo({
                  ...tenentInfo,
                  lastName: e.target.value
                })
              }}

              onEmailAddressChange={(e) => {
                setTenantInfo({
                  ...tenentInfo,
                  email: e.target.value
                })
              }}


              onCellNumberChange={(e) => {
                setTenantInfo({
                  ...tenentInfo,
                  cellNo: e.target.value
                })
              }}

              formData={tenentInfo}

            />}


            {leaseCreateStep === 3 && <RentCharges
              formData={formFields}
              onRentDateChange={(e) => {
                setFormFields({
                  ...formFields,
                  rentStartDate: e.target.value
                })
              }}
              onRentFrequencyChange={(e) => {
                setFormFields({
                  ...formFields,
                  rentFrequency: e.target.value
                })
              }}
              isChargeLate={(bool) => {
                setFormFields({
                  ...formFields,
                  chargeLate: bool
                })
              }}

              onLatePercentageChange={(e) => {
                setFormFields({
                  ...formFields,
                  latePercentage: e.target.value
                })
              }}


              onLateGraceDays={(e) => {
                setFormFields({
                  ...formFields,
                  graceDays: e.target.value
                })
              }}

              onAdditionalCharges={(data) => {
                let preeList = formFields.additionalCharges
                preeList.push(data)
                setFormFields({
                  ...formFields,
                  additionalCharges: preeList
                })
              }}

              onItemDeleteChanges={(item) => {
                let list = [...formFields.additionalCharges]
                list = list.filter((i) => i.title !== item.title)
                setFormFields({
                  ...formFields,
                  additionalCharges: list
                })
              }}

            />}
            {leaseCreateStep === 4 && <Deposite
              formData={formFields}
              onSecurityDepositChange={(value) => {
                setFormFields({
                  ...formFields,
                  securityDeposit: value
                })
              }}

              onLastDateToSubmitChange={(e) => {
                setFormFields({
                  ...formFields,
                  lastDayToSubmitDeposit: e.target.value
                })
              }}


              onDepositAmountChange={(e) => {
                setFormFields({
                  ...formFields,
                  securityDepositAmount: e.target.value
                })
              }}
            />}
            {leaseCreateStep === 5 && <Esignature
              formData={formFields}
              isEsignature={(value) => {
                setFormFields({
                  ...formFields,
                  isEsignature: value
                })
              }}

              signatureObject={(sign) => {
                setFormFields({
                  ...formFields,
                  eSignature: sign
                })
              }}
              isDrawSignature={(value) => {
                setFormFields({
                  ...formFields,
                  drawSignature: value
                })
              }}

            />}
            {leaseCreateStep === 6 && <Overview
              tenentInfo={tenentInfo}
              formData={formFields}
              onClose={onBack}
            />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMain;
