import React, { useState, useEffect } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import AddUnitPopup from "../../../popup/unitpopup";
import AddPropertyPopup from "../../../popup/addPropertyPopup";

import BaseUrl from "../../../../constants/BaseUrl";
import { ChevronDown } from "lucide-react";
import { useSelector, useDispatch } from "react-redux"
import * as LeaseAction from "../../../../store/actions/lease/LeaseAction"
const Terms = ({ onPropertySelect, selectedProperty, onUnitSelect, selectedUnit, selectedUnitTerm, onLeaseterm, onStartDate, onEndDate, selectedStartDate, selectedEndDate, formData }) => {
  const dispatch = useDispatch()

  const [allProperties, setAllProperties] = useState([]);
  const [allUnits, setAllUnits] = useState([]);
  const { leaseData, leaseUpdate } = useSelector((state) => state.lease)


  const [showUnitPopUp, setShowUnitpopUp] = useState(false);
  const [showPropertyPopUp, setshowPropertypopUp] = useState(false);

  const { loginSuccess } = useSelector((state) => state.auth);




  const GetAllProperties = () => {
    const requestOptions = {
      method: "GET",

      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/property/getAllPropertiesbyLandlord/${loginSuccess?.user?._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllProperties(result.data);
        }
      })
      .catch((error) => console.error(error));
  };


  const GetUnitByPropertyId = (id) => {

    const requestOptions = {
      method: "GET",

      redirect: "follow"
    };

    fetch(`${BaseUrl}api/property/getUnitByPropertyId/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result.success) {
          let availableUnits = []
          const unit = result?.unitData?.unitData
          for (const key in unit) {
            if (unit[key].occupied == false) {
              availableUnits.push(unit[key])
            }
          }

          setAllUnits(availableUnits)
        }
      })
      .catch((error) => console.error(error));
  }



  useEffect(() => {
    GetAllProperties();
  }, []);

  const openPopup = () => {
    setShowUnitpopUp(true);
  };

  const closePopup = () => {
    setShowUnitpopUp(false);
  };

  const openPropertyPopup = () => {
    setshowPropertypopUp(true);
  };

  const closePropertyPopup = () => {
    setshowPropertypopUp(false);
  };

  useEffect(() => {
    if (leaseUpdate && formData.propertyInfo) {
      GetUnitByPropertyId(formData.propertyInfo._id)

      // GetUnitByPropertyId(formData.propertyInfo._id)
    }

  }, [leaseUpdate])

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty or undefined date
    return dateString.split("T")[0]; // Extracts the date part
  };
  return (
    <div className="w-[60%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Property Unit"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Choose your desired property and unit."
          classes="text-darkColor/50 font-popinsMedium text-xs"
        />
      </div>


      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className="flex justify-between mt-6">
          <div className="w-[49%]">
            <LabelTag
              name="Property"
              isStaric={false}
              classes="text-darkColor text-left !text-xs !font-popinsMedium"
            />
            <div
              className="w-full bg-bgColor py-[10px] rounded-md text-sm px-3 cursor-pointer flex justify-between"
              onClick={openPropertyPopup}
            >
              <p className="font-popinsRegular text-darkColor/40">{selectedProperty}</p>
              <ChevronDown color="#1A1A1A2D" size={18} />
            </div>

          </div>
          <div className="w-[49%]">
            <LabelTag
              name="Unit"
              isStaric={false}
              classes="text-darkColor !text-left !text-xs !font-popinsMedium"
            />
            <div
              className="w-full bg-bgColor py-[10px] rounded-md text-sm px-3 cursor-pointer flex justify-between"
              onClick={openPopup}
            >
              <p className="font-popinsRegular text-darkColor/40">{selectedUnit}</p>
              <ChevronDown color="#1A1A1A2D" size={18} />
            </div>

          </div>
        </div>
        <div className="text-center mt-6">
          <ParagraphTag
            content="Lease Terms"
            classes="text-darkColor font-popinsSemiBold text-base"
          />
          <ParagraphTag
            content="Select the duration of the lease."
            classes="text-darkColor/50 font-popinsMedium text-xs"
          />
        </div>
        <div className="flex justify-between py-4 w-[50%] mx-auto">
          <div
            onClick={() => {
              onLeaseterm("Pre Rent")
            }}
            className={`${selectedUnitTerm === "Pre Rent" ? 'bg-themeColor text-white' : 'bg-[#E43B3B1A]'}  rounded-lg flex justify-center py-2 hover:bg-themeColor hover:text-white cursor-pointer w-[45%] mr-[2%]`}>
            <p className="text-xs font-popinsMedium">Pre Rent</p>
          </div>
          <div
            onClick={() => {
              onLeaseterm("Post Rent")
            }}
            className={`${selectedUnitTerm === "Post Rent" ? 'bg-themeColor text-white' : 'bg-[#E43B3B1A]'} flex justify-center  rounded-lg py-2 hover:bg-themeColor hover:text-white cursor-pointer w-[45%] mr-[2%]`}>
            <p className="text-xs font-popinsMedium">Post Rent</p>
          </div>
          {/* <div
            onClick={() => {
              onLeaseterm("One Time")
            }}
            className={`${selectedUnitTerm === "One Time" ? 'bg-themeColor text-white' : 'bg-[#E43B3B1A]'}  rounded-lg flex justify-center py-2 hover:bg-themeColor hover:text-white cursor-pointer w-[32%]`}>
            <p className="text-xs font-popinsMedium">One Time</p>
          </div> */}
        </div>
        <div className="flex justify-between mt-2">
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="Start Date"
              classes="!text-xs !font-popinsMedium"
            />

            <input

              type="date"
              placeholder="Please select end date"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
              onChange={(event) => {
                onStartDate(event.target.value)
              }} // Handle date change
              value={formatDate(formData.startDate)} // Bind selectedDate to value
            />
          </div>
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="End Date"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="date"
              placeholder="Please select end date"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
              onChange={(event) => {
                onEndDate(event.target.value)
              }} // Handle date change
              value={formatDate(formData.endDate)}  // Bind selectedDate to value
            />
          </div>
        </div>

      </form>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(LeaseAction.ChangePropertyCreateSteps(2))
            }}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
      {showUnitPopUp && <AddUnitPopup
        allUnits={allUnits}
        onClose={closePopup}
        onSelectUnit={(item) => {
          onUnitSelect(item)
          closePopup()
          // alert(JSON.stringify(on))
        }}

      />}


      {showPropertyPopUp && <AddPropertyPopup
        onSelect={(item) => {
          onPropertySelect(item)
          GetUnitByPropertyId(item._id)
        }}
        allProperties={allProperties} onClose={closePropertyPopup} />}
    </div>
  );
};

export default Terms;
