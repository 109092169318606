import {
    ChangePropertyCreateStepsActionConst,
    createPropertyActionConst,
    CreateNewPropertyActionConst,
    GetAllPropertiesByLandlord,
    ChangePropertyStatusActionConst,
    GetPropertyDetailsActionConst,
    ClearAllPrevStateActionConst,
    UpdatePropertyActionConst
} from "../../constants/PropertyActionConst"
import BaseUrl from "../../../constants/BaseUrl";


import { ShowSuccess } from "../../../utils/ToasFunction"



export const GetAllPropertyByLanlord = (lanlordId) => {
    return async dispatch => {


        dispatch({
            type: GetAllPropertiesByLandlord.GET_ALL_PROPERTIES_REQ,
        })
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(`${BaseUrl}api/property/getPropertiesByLanloard/${lanlordId}?page=1&limit=30`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    dispatch({
                        type: GetAllPropertiesByLandlord.GET_ALL_PROPERTIES_SUCC,
                        property: result.data
                    })

                } else {
                    dispatch({
                        type: GetAllPropertiesByLandlord.GET_ALL_PROPERTIES_FAIL,
                        property: null
                    })
                }
            })
            .catch((error) => console.error(error));
    }
}



export const ChangePropertyCreateSteps = (step) => {
    return async dispatch => {
        dispatch({
            type: ChangePropertyCreateStepsActionConst.CHANGE_PROPERTY_STEPS,
            step: step
        })
    }
}








export const CreateNewPropertyAction = (landlordId, property) => {
    return async dispatch => {
        dispatch({
            type: CreateNewPropertyActionConst.CREATE_NEW_PROPERTY_REQ
        })
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "propertyName": property?.propertyName,
            "landlordId": landlordId,
            "category": property?.category,
            "type": property?.type,
            "completeAddress": property?.completeAddress,
            "streetOne": property?.streetOne,
            "streetTwo": property?.streetTwo,
            "city": property?.city,
            "country": property?.country,
            "state": property?.state,
            "zipCode": property?.zipCode,
            "thumbnailImage": property?.thumbnailImage,
            "images": property?.images,
            "longitude": "67.0680126",
            "latitude": "24.8611911",
            "unitData": property.unitData,
            "unitCount": property.unitData.length,
            "unitCreationFields": property.unitCreationFields
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${BaseUrl}api/property/createProperty`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status === "Success") {

                    dispatch({
                        type: CreateNewPropertyActionConst.CREATE_NEW_PROPERTY_SUCC,
                        success: result
                    })
                } else {
                    dispatch({
                        type: CreateNewPropertyActionConst.CREATE_NEW_PROPERTY_FAIL,
                        error: result
                    })
                }
            })
            .catch((error) => console.error(error));
    }
}



export const UpdatePropertyAction = (formData) => {

    return async dispatch => {
        dispatch({
            type: UpdatePropertyActionConst.UPDATE_PROPERTY_REQ
        })
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify(formData);

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };


        fetch(`${BaseUrl}api/property/updateProperty`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    console.log(result)
                    dispatch({
                        type: UpdatePropertyActionConst.UPDATE_PROPERTY_SUCC,
                        success: result
                    })
                } else {
                    dispatch({
                        type: UpdatePropertyActionConst.UPDATE_PROPERTY_FAIL
                    })
                }
            })
            .catch((error) => console.error(error));
    }
}

export const CreatePropertyStepOneAction = (category, type) => {
    return async dispatch => {
        const data = {
            category: category,
            type: type
        }
        dispatch({
            type: createPropertyActionConst.CREATE_PROPERTY_STEP_ONE,
            data: data
        })
    }
}


export const CreatePropertyStepTwoAction = (data) => {
    return async dispatch => {
        dispatch({
            type: createPropertyActionConst.CREATE_PROPERTY_STEP_TWO,
            data: data
        })
    }
}



export const ClearAllPropertyCreateStatus = () => {
    return async dispatch => {
        dispatch({
            type: CreateNewPropertyActionConst.CREATE_NEW_PROPERTY_CLEAR
        })
    }
}


export const CreatePropertyStepThreeAction = (unitCreationFields, unitData) => {
    return async dispatch => {
        dispatch({
            type: createPropertyActionConst.CREATE_PROPERTY_STEP_THREE,
            unitData: unitData,
            unitCreationFields: unitCreationFields
        })
    }
}




export const ChangePropertyStatusAction = (data) => {

    return async dispatch => {
        dispatch({
            type: ChangePropertyStatusActionConst.CHANGE_PROPERTY_STATUS_REQ,
        })

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "propertyId": data.id,
            "status": data.status
        });

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${BaseUrl}api/property/changePropertyStatus`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status === 200) {
                    dispatch({
                        type: ChangePropertyStatusActionConst.CHANGE_PROPERTY_STATUS_SUCC,
                        data: data
                    })
                    ShowSuccess("Property status updated successfully")
                } else {
                    dispatch({
                        type: ChangePropertyStatusActionConst.CHANGE_PROPERTY_STATUS_FAIL,

                    })
                }
            })
            .catch((error) => console.error(error));


    }
}


export const GetPropertyDetailsAction = (id) => {
    return async dispatch => {
        dispatch({
            type: GetPropertyDetailsActionConst.GET_PROPERTY_DETAIL_REQ,
        })
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(`${BaseUrl}api/property/getPropertyDetails/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    console.log(result)
                    dispatch({
                        type: GetPropertyDetailsActionConst.GET_PROPERTY_DETAIL_SUCC,
                        data: result.data
                    })
                }
            })
            .catch((error) => console.error(error));
    }

}




export const ClearAllPrevStateAction = () => {
    return async dispatch => {
        dispatch({
            type: ClearAllPrevStateActionConst.CLEAT_ALL_PREV_STATE,
        })
    }
}