import React, { useEffect, useState } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import { useSelector } from "react-redux";
import BaseUrl from "../../../constants/BaseUrl";
import { ArrowRight } from "lucide-react";
import NoData from "../../reusebale/noData"
import Myloader from "../../reusebale/myLoader"

const TenantsSection = ({ onViewDetails, onTenantList }) => {
  const { loginSuccess } = useSelector((state) => state.auth);
  const { rightPanel } = db;
  const [allTenants, setAllTenants] = useState([]);
  const [loading, setLoading] = useState(false);

  const GetAllTenant = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}tenant/getAllTenantByLanlordId/${loginSuccess.user._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllTenants(result.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    GetAllTenant();
  }, []);
  return (
    <div className="bg-white rounded-2xl py-5 px-4 w-full mt-5 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="flex justify-between items-center ">
        <ParagraphTag
          content={rightPanel.tenants}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <ParagraphTag
          content={rightPanel.viewall}
          classes="text-themeColor font-popinsSemiBold text-sm cursor-pointer"
          onTrigger={onTenantList}
        />
      </div>

      <div className="flex border-b-[1px] border-darkColor/40 pb-1 mt-6">
        <div className="w-[45%]">
          <ParagraphTag
            content={rightPanel.tenants}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[29%] text-end">
          <ParagraphTag
            content={"Personal Information"}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[25%] text-end">
          <ParagraphTag
            content={rightPanel.quick}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
      </div>
      <div className="h-[300px] overflow-y-scroll panelScroll">
        {allTenants?.map((item, i) => (
          <div className="flex mt-4 justify-between items-center">
            <div className="w-[45%] flex items-center">
              <div className="w-[75%] pl-1">
                <ParagraphTag
                  content={`TEN-${item?._id?.slice(0, 10)}`}
                  classes="text-darkColor/40 font-popinsMedium text-xs"
                />
                <ParagraphTag
                  content={`${item?.firstName} ${item?.lastName}`}
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
            <div className="w-[34%] text-center">
              <ParagraphTag
                content={item?.email}
                classes="text-darkColor/40 font-popinsMedium text-xs"
              />
              <ParagraphTag
                content={item?.cellNo}
                classes="text-darkColor font-popinsMedium text-sm"
              />
            </div>
            <div className="w-[19%] flex justify-end items-center">
              <div
                className="flex justify-center items-center bg-themeColor w-[35%] h-8 rounded-lg cursor-pointer mr-5"
                onClick={() => {
                  onViewDetails(item);
                }}
              >
                <ArrowRight color="#FFFF" size={18} strokeWidth={2} />
              </div>
            </div>
          </div>
        ))}

        {loading && (
          <div className="flex justify-center items-center w-full mt-20">
            <Myloader />
          </div>
        )}
        {(allTenants.length === 0 && !loading) && (
          <div className="w-full mt-5 flex justify-center">
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
};

export default TenantsSection;
