export const LeaseStepActionConst = {
    LEASE_STEP_CHANGE: "LEASE_STEP_CHANGE",

}


export const LeaseDetailActionConst = {
    LEASE_DETAIL_DATA: "LEASE_DETAIL_DATA",
}


export const ClearAllLeaseDetails = {
    CLEAR_ALL_LEASE_DETAIL: "CLEAR_ALL_LEASE_DETAIL",
}



