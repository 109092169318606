
import { LoginActionConst, SignUpActionConst, ClearSignUpFieldsActionConst, GetLandlordProfileActionConst, LogoutActionConst } from "../../constants/authActionConst"
const initialState = {
    loader: false,
    loginSuccess: null,
    loginError: null,


    signUpSuccess: null,
    signUpError: null,

    userCompleteProfile: null,


    profileCompleted: false,

    sendProifleSucc: null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case LoginActionConst.USER_LOGIN_REQUEST:
            state = {
                ...state,
                loader: true,
                signUpError: null,
                signUpSuccess: null
            }
            break

        case LoginActionConst.USER_LOGIN_SUCC:

            state = {
                ...state,
                loader: false,
                loginSuccess: action.result,
                loginError: null
            }
            break

        case LoginActionConst.USER_LOGIN_FAIL:
            state = {
                ...state,
                loader: false,
                loginError: action.result,
                loginSuccess: null
            }
            break


        //Sign Up
        case SignUpActionConst.USER_SIGNUP_REQUEST:
            state = {
                ...state,
                loader: true
            }
            break

        case SignUpActionConst.USER_SIGNUP_SUCC:
            state = {
                ...state,
                loader: false,
                signUpSuccess: action.result,
                signUpError: null
            }
            break

        case SignUpActionConst.USER_SIGNUP_FAIL:
            state = {
                ...state,
                loader: false,
                signUpError: action.result,
                signUpSuccess: null
            }
            break

        case ClearSignUpFieldsActionConst.SIGNUP_CLEAR:
            state = {
                ...state,
                loader: false,
                signUpError: action.result,
                signUpSuccess: null,
                signUpError: null,
                loginError: null
            }
            break

        //Landlaord complete profile
        case GetLandlordProfileActionConst.GET_USER_PROFILE_REQ:
            state = {
                ...state,
                loader: true,
                userCompleteProfile: action.result
            }
            break


        case GetLandlordProfileActionConst.GET_USER_PROFILE_SUCCESS:

            state = {
                ...state,
                loader: false,
                userCompleteProfile: action.result,
                profileCompleted: action?.result?.companyInformation && action?.result?.personalInformation ? true : false
            }
            break

        case GetLandlordProfileActionConst.GET_USER_PROFILE_FAIL:
            state = {
                ...state,
                loader: false,
                userCompleteProfile: "ERROR HERE"
            }
            break

        //Logout Action
        case LogoutActionConst.LOGOUT_ACTION:
            state = {
                ...state,
                loader: false,
                loginSuccess: null,
                loginError: null,


                signUpSuccess: null,
                signUpError: null,

                userCompleteProfile: null,


                profileCompleted: false,

                sendProifleSucc: null
            }
            break




    }
    return state

}