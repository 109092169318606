import React, { useEffect, useState } from "react";
import IconCards from "../../../reusebale/iconCards";
import ParagraphTag from "../../../reusebale/Paragraph";
import ButtonTag from "../../../reusebale/button";
import db from "../../../../db/index.json";
import { useSelector, useDispatch } from "react-redux";
import * as PropertyAction from "../../../../store/actions/property/PropertyAction";
const PropertyType = () => {
  const PropertyTypes = useSelector((state) => state.data.propertyTypes);
  const { createNewPropertyBody, isUpdate } = useSelector((state) => state.property);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    selectedCategory: null,
    selectedtype: null,
    typeList: [],
  });

  useEffect(() => {
    if (isUpdate) {
      let list = [];
      if (createNewPropertyBody.category) {
        list = PropertyTypes.find(
          (i) => i.category === createNewPropertyBody.category
        );
        setFormData({
          ...formData,
          typeList: list?.types,
        });
      }
    }


  }, [isUpdate]);

  const NextHandler = (e) => {
    e.preventDefault();
    dispatch(PropertyAction.ChangePropertyCreateSteps(2));
  };

  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content={db.properties.addProperty}
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <ParagraphTag
          content={db.properties.addPropertyDesc}
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <div className="overflow-y-scroll h-[80%] panelScroll">
        <div className="flex w-[100%] justify-center items-center mt-6">
          {PropertyTypes?.map((item) => {
            return (
              <div
                key={item.category}
                className="w-[50%] flex flex-col items-center mx-auto  cursor-pointer"
                onClick={() => {
                  dispatch(
                    PropertyAction.CreatePropertyStepOneAction(
                      item.category,
                      null
                    )
                  );
                  setFormData({
                    ...formData,
                    selectedCategory: item.category,
                    typeList: item.types,
                    selectedtype: null,
                  });
                }}
              // onClick={() => handleCardClick(item.title, "1")}
              >
                <IconCards
                  mainClasses={`h-36 w-[130px] ${createNewPropertyBody?.category === item?.category
                    ? "!border-2 !border-themeColor"
                    : ""
                    }`}
                  imgClasses="w-[40px]"
                  path={`/assets/daashboard/create-propety/${createNewPropertyBody?.category === item.category
                    ? item.activeImage
                    : item.inActiveImage
                    }`}
                  // path={item.active ? item?.activeImage : item?.inActiveImage}
                  title={item.category}
                  titleClasses={
                    createNewPropertyBody?.category === item.category
                      ? "text-themeColor"
                      : "text-darkColor"
                  }
                />
              </div>
            );
          })}
        </div>
        <ParagraphTag
          content={db.properties.desc2}
          classes="text-[#686868] font-popinsRegular text-xs py-5 text-center"
        />
        <div className="flex w-[40%] mx-auto justify-between flex-wrap">
          {formData?.typeList.map((item) => (
            <div
              key={item?.name}
              className={`  w-[46%] cursor-pointer`}
              onClick={() => {
                dispatch(
                  PropertyAction.CreatePropertyStepOneAction(
                    formData?.selectedCategory,
                    item.name
                  )
                );
                setFormData({
                  ...formData,
                  selectedtype: item.name,
                });
              }}
            >
              <IconCards
                mainClasses={`h-32 ${createNewPropertyBody?.type === item.name
                  ? "!border-2 !border-themeColor"
                  : ""
                  }`}
                imgClasses="w-[40px]"
                // path={item?.active ? item?.imagePathActiv : item?.imagePath}
                path={`/assets/daashboard/create-propety/${createNewPropertyBody?.type === item.name
                  ? item.imagePathActive
                  : item.imagePath
                  }`}
                title={item?.name}
                titleClasses={
                  createNewPropertyBody?.type === item.name
                    ? "text-themeColor"
                    : "text-darkColor"
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={NextHandler}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyType;
