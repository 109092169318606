import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";

const CompanyInfoSection = ({ CompanyInfo }) => {
  return (
    <div className="bg-white rounded-xl mt-6 w-full">
      <div className="px-4 pt-4">
        <ParagraphTag
          content="Company Info"
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />
      </div>
      <div className="flex mt-1 items-center">
        <div className="w-[30%]">
          <ImageTag
            path={CompanyInfo?.companyInformation?.companyLogo}
            classes="w-[230px] rounded-full"
            altText="logo"
          />
        </div>
        <div className="w-[70%] px-2">
          <div className="pt-3">
            <ParagraphTag
              content="Complete Address"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={CompanyInfo?.companyInformation?.completeAddress}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-2 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="City"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={CompanyInfo?.companyInformation?.city}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="Country"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={CompanyInfo?.companyInformation?.country}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
          </div>
          <div className="pt-2 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="State"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={CompanyInfo?.companyInformation?.state}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="Zip Code"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={CompanyInfo?.companyInformation?.zipCode}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoSection;
