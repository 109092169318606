import React from "react";
import ButtonTag from "../../../reusebale/button";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import * as DataAction from "../../../../store/actions/data/DataAction";
import { useSelector, useDispatch } from "react-redux";

const Info = ({ onTitleChange, onDescriptionChange, formFields }) => {
  const dispatch = useDispatch();
  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="h-[10%] text-center">
        <ParagraphTag
          content="Broadcast Information"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <ParagraphTag
          content="Enter the title and description you want to send to your tenants."
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className="w-[80%] mx-auto">
          <div className="mt-3">
            <LabelTag
              isStaric={false}
              name="Title"
              classes="!text-xs !font-popinsMedium"
            />

            <input
              value={formFields.title}
              onChange={(e) => {
                onTitleChange(e.target.value);
              }}
              type="text"
              placeholder="Enter title here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="mt-2">
            <LabelTag
              isStaric={false}
              name="Broadcast Message"
              classes="!text-xs !font-popinsMedium"
            />
            <textarea
              type="text"
              cols={10}
              value={formFields.description}
              onChange={(e) => {
                onDescriptionChange(e.target.value);
              }}
              placeholder="Enter description here"
              className="w-full h-28 mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            ></textarea>
          </div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={(e) => {
              e.preventDefault();

              dispatch(DataAction.ChangeBroadcastCurrentStep(2));
            }}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Info;
