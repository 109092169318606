import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";
import ReactPaginate from "react-paginate";
import MapSection from "./map";
import NoData from "../../reusebale/noData";
import Myloader from "../../reusebale/myLoader"
import {
  ArrowUpDown,
  ArrowDownUp,
  Map,
  Plus,
  SquareCheck,
  SquareX,
  X,
  Pencil,
} from "lucide-react";
import db from "../../../db/index.json";
import * as PropertyAction from "../../../store/actions/property/PropertyAction";
import { useDispatch, useSelector } from "react-redux";

const PropertyTable = ({ onViewDetails, onViewCreate, onViewUpdate }) => {
  const { allProperties } = useSelector((state) => state.property);
  const [data, setData] = useState([]);
  const { loginSuccess } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PropertyAction.GetAllPropertyByLanlord(loginSuccess?.user?._id));
    dispatch(PropertyAction.ChangePropertyCreateSteps(1));
  }, []);

  const ChangePropertyStatus = (id, status) => {
    const data = {
      id: id,
      status: status === "active" ? "inActive" : "active",
    };
    dispatch(PropertyAction.ChangePropertyStatusAction(data));
  };

  const { properties } = db;
  const [showMap, setShowMap] = useState(false);
  useEffect(() => {
    if (Array.isArray(allProperties)) {
      setData(allProperties);
      setLoading(false);
    } else {
      console.error("Expected `allProperties` to be an array:", allProperties);
      setData([]);
    }
  }, [allProperties]);
  const toggleMap = () => {
    setShowMap(!showMap); // Map visibility toggle
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const paginatedData =
    data?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage) ||
    [];

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "name", // Default sort key
    direction: "asc", // Default sort direction
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (typeof a[key] === "string" && typeof b[key] === "string") {
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      }
    });

    setData(sortedData);
  };

  return (
    <div className="flex justify-between h-full pt-5">
      <div className={`${showMap ? "w-[64%]" : "w-full"} h-full`}>
        <div className={`bg-white h-[90%]`}>
          <div className="w-[95%] mx-auto">
            <div className="flex justify-between py-5">
              <div className={`${showMap ? "w-[50%]" : "w-[70%]"}`}>
                <ParagraphTag
                  content={properties.heading}
                  classes="text-darkColor font-popinsSemiBold text-base"
                />
              </div>

              <div
                className={`flex justify-between ${
                  showMap ? "w-[50%]" : "w-[30%]"
                }`}
              >
                <button
                  className={`${
                    showMap ? "border-themeColor" : "border-[#6F6F6F]/70"
                  } border w-[48%] rounded-md flex items-center justify-center py-2`}
                  onClick={toggleMap}
                >
                  {showMap ? (
                    <X color="#FF814E" size={15} />
                  ) : (
                    <Map size={15} />
                  )}

                  <ParagraphTag
                    content={showMap ? properties.closeMap : properties.map}
                    classes={`${
                      showMap ? "text-themeColor" : "text-[#6F6F6F]"
                    } font-popinsSemiBold text-xs pl-2`}
                  />
                </button>
                <button
                  className="w-[48%] bg-themeColor rounded-md flex items-center justify-center py-2"
                  onClick={onViewCreate}
                >
                  <Plus color="#FFFFFF" size={15} />
                  <ParagraphTag
                    content={properties.new}
                    classes="text-white font-popinsSemiBold text-xs pl-2"
                  />
                </button>
              </div>
            </div>
            <div className="overflow-x-auto w-full h-[400px] panelScroll mt-1">
              <table className="w-full ">
                <thead className="sticky top-0 bg-white z-10">
                  <tr className="border-b border-black/20">
                    <th className="w-[25%] text-left pb-2">
                      <span className="flex">
                        <ParagraphTag
                          content={properties.heading}
                          classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                        />
                        {sortConfig.key === "propertyName" ? (
                          sortConfig.direction === "asc" ? (
                            <ArrowUpDown
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("propertyName")}
                            />
                          ) : (
                            <ArrowDownUp
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("propertyName")}
                            />
                          )
                        ) : (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("propertyName")}
                          />
                        )}
                      </span>
                    </th>
                    <th className="w-[10%] pb-2">
                      <span className="flex justify-center">
                        <ParagraphTag
                          content={properties.status}
                          classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                        />
                        {sortConfig.key === "status" ? (
                          sortConfig.direction === "asc" ? (
                            <ArrowUpDown
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("status")}
                            />
                          ) : (
                            <ArrowDownUp
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("status")}
                            />
                          )
                        ) : (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        )}
                      </span>
                    </th>
                    {!showMap && (
                      <>
                        <th className="w-[10%] pb-2">
                          <span className="flex justify-center">
                            <ParagraphTag
                              content={properties.units}
                              classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                            />
                            {sortConfig.key === "unitCount" ? (
                              sortConfig.direction === "asc" ? (
                                <ArrowUpDown
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("unitCount")}
                                />
                              ) : (
                                <ArrowDownUp
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("unitCount")}
                                />
                              )
                            ) : (
                              <ArrowUpDown
                                className="cursor-pointer"
                                size={13}
                                color="#6F6F6F"
                                onClick={() => requestSort("unitCount")}
                              />
                            )}
                          </span>
                        </th>
                        <th className="w-[10%] pb-2">
                          <span className="flex justify-center">
                            <ParagraphTag
                              content={properties.leased}
                              classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                            />
                            {sortConfig.key === "leaseUnitCount" ? (
                              sortConfig.direction === "asc" ? (
                                <ArrowUpDown
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("leaseUnitCount")}
                                />
                              ) : (
                                <ArrowDownUp
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("leaseUnitCount")}
                                />
                              )
                            ) : (
                              <ArrowUpDown
                                className="cursor-pointer"
                                size={13}
                                color="#6F6F6F"
                                onClick={() => requestSort("leaseUnitCount")}
                              />
                            )}
                          </span>
                        </th>
                        <th className="w-[10%] pb-2">
                          <span className="flex justify-center">
                            <ParagraphTag
                              content={"State"}
                              classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                            />
                            {sortConfig.key === "state" ? (
                              sortConfig.direction === "asc" ? (
                                <ArrowUpDown
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("state")}
                                />
                              ) : (
                                <ArrowDownUp
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("state")}
                                />
                              )
                            ) : (
                              <ArrowUpDown
                                className="cursor-pointer"
                                size={13}
                                color="#6F6F6F"
                                onClick={() => requestSort("state")}
                              />
                            )}
                          </span>
                        </th>
                      </>
                    )}
                    <th className="w-[15%] pb-2">
                      <span className="flex justify-center">
                        <ParagraphTag
                          content={"Category (Type)"}
                          classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                        />
                        {sortConfig.key === "category" ? (
                          sortConfig.direction === "asc" ? (
                            <ArrowUpDown
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("category")}
                            />
                          ) : (
                            <ArrowDownUp
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("category")}
                            />
                          )
                        ) : (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("category")}
                          />
                        )}
                      </span>
                    </th>
                    <th className="w-[10%] pb-2">
                      <span className="flex justify-center">
                        <ParagraphTag
                          content={"Type"}
                          classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                        />
                        {sortConfig.key === "type" ? (
                          sortConfig.direction === "asc" ? (
                            <ArrowUpDown
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("type")}
                            />
                          ) : (
                            <ArrowDownUp
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("type")}
                            />
                          )
                        ) : (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("type")}
                          />
                        )}
                      </span>
                    </th>

                    <th className="w-[10%] text-right pr-4 pb-2">
                      <ParagraphTag
                        content={properties.actions}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {paginatedData?.map((item) => (
                    <tr
                      key={item.id}
                      className="hover:bg-gray-100 rounded-md cursor-pointe"
                    >
                      <td className="py-2">
                        <div className="flex items-center">
                          <div className="">
                            <ImageTag
                              path={item?.thumbnailImage?.url}
                              classes="w-[50px] rounded-full h-[50px] object-cover"
                              altText="logo"
                            />
                          </div>
                          <div
                            className=" cursor-pointer pl-3"
                            onClick={() => {
                              onViewDetails(item._id);
                            }}
                          >
                            <ParagraphTag
                              content={`PROP-${item?._id?.slice(1, 10)}`}
                              classes="text-darkColor/50 font-popinsMedium text-xs"
                            />
                            <ParagraphTag
                              content={item.propertyName}
                              classes="text-darkColor font-popinsSemiBold text-sm"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="py-2 font-popinsMedium text-darkColor text-sm">
                        <span className="flex justify-center">
                          {item.status === "active" ? (
                            <SquareCheck color="#01CB78" size={20} />
                          ) : (
                            <SquareX color="#E43B3B" size={20} />
                          )}
                        </span>
                      </td>
                      {!showMap && (
                        <>
                          <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                            {item?.unitCount}
                          </td>
                          <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                            {item?.leaseUnitCount}
                          </td>
                          <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                            {`${item.state}`}
                          </td>
                        </>
                      )}
                      <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                        {`${item.category}`}
                      </td>
                      <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                        {`${item.type}`}
                      </td>

                      <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm">
                        <div className="flex justify-end">
                          <button
                            className="flex items-center px-2 py-1 border border-themeColor rounded-lg mr-1"
                            onClick={() => {
                              onViewUpdate(item._id);
                            }}
                          >
                            <Pencil color="#FF814E" size={18} />
                          </button>
                          <label class="inline-flex items-center cursor-pointer relative pl-3">
                            <input
                              checked={item.status === "active" ? true : false}
                              value={true}
                              onChange={() => {
                                ChangePropertyStatus(item._id, item.status);
                              }}
                              type="checkbox"
                              class="sr-only peer"
                            />
                            <div class="relative w-11 h-6 bg-[#E43B3B38] peer-focus:outline-none rounded-full peer dark:bg-[#E43B3B38] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#E43B3B] after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#E43B3B] after:border-[#E43B3B] after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#E43B3B] peer-checked:bg-[#E43B3B38]"></div>
                          </label>

                          {/* <button className="flex items-center px-2 border border-[#6F6F6F] rounded-lg mr-2">
                            <Bell color="#6F6F6F" size={18} />
                          </button>
                          <button className="flex items-center px-2 border border-[#6F6F6F] rounded-lg mr-2">
                            <Phone color="#6F6F6F" size={18} />
                          </button>
                          <button className="flex items-center px-2 border border-[#6F6F6F] rounded-lg mr-2">
                            <Mail color="#6F6F6F" size={18} />
                          </button>
                          <button className="flex items-center border border-themeColor text-themeColor font-popinsSemiBold px-4 py-1 rounded text-xs">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5 pr-2"
                              viewBox="0 0 15 19"
                              fill="none"
                            >
                              <path
                                d="M10.5938 2.5H12C13.2305 2.5 14.25 3.51953 14.25 4.75V16C14.25 17.2656 13.2305 18.25 12 18.25H3C1.73438 18.25 0.75 17.2656 0.75 16V4.75C0.75 3.51953 1.73438 2.5 3 2.5H4.40625H4.72266C5.00391 1.23438 6.12891 0.25 7.5 0.25C8.83594 0.25 9.99609 1.23438 10.2422 2.5H10.5938ZM3 4.1875C2.68359 4.1875 2.4375 4.46875 2.4375 4.75V16C2.4375 16.3164 2.68359 16.5625 3 16.5625H12C12.2812 16.5625 12.5625 16.3164 12.5625 16V4.75C12.5625 4.46875 12.2812 4.1875 12 4.1875H11.4375V5.03125C11.4375 5.52344 11.0508 5.875 10.5938 5.875H7.5H4.40625C3.91406 5.875 3.5625 5.52344 3.5625 5.03125V4.1875H3ZM7.5 3.90625C7.95703 3.90625 8.34375 3.55469 8.34375 3.0625C8.34375 2.60547 7.95703 2.21875 7.5 2.21875C7.00781 2.21875 6.65625 2.60547 6.65625 3.0625C6.65625 3.55469 7.00781 3.90625 7.5 3.90625Z"
                                fill="#FF814E"
                              />
                            </svg>
                            Renew Lease
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {loading && (
                <div className="flex justify-center items-center w-full mt-20">
                  <Myloader />
                </div>
              )}
              {(paginatedData.length === 0 && !loading) && (
                <div className="w-full mt-5">
                  <NoData />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center h-[10%]">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            pageCount={Math.ceil(data.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName="flex space-x-2 items-center"
            pageClassName="px-3 py-1 border rounded-md text-sm font-popinsMedium cursor-pointer"
            previousClassName="px-3 py-1 border rounded-l-md text-sm cursor-pointer font-popinsMedium"
            nextClassName="px-3 py-1 border rounded-r-md text-sm font-popinsMedium cursor-pointer font-popinsMedium"
            disabledClassName="opacity-50 !cursor-not-allowed"
            activeClassName="bg-themeColor text-white"
            breakClassName="px-3 py-1 text-sm font-popinsMedium"
          />
        </div>
      </div>{" "}
      :
      {showMap && (
        <div className="w-[35%] py-5">
          <MapSection />
        </div>
      )}
    </div>
  );
};

export default PropertyTable;
