import React from "react";
import db from "../../../../db/index.json";
import ParagraphTag from "../../../reusebale/Paragraph";
import IconCards from "../../../reusebale/iconCards";
import ButtonTag from "../../../reusebale/button";
import { Plus, Trash } from "lucide-react";
import LabelTag from "../../../reusebale/label";
import * as LeaseAction from "../../../../store/actions/lease/LeaseAction"
import { useDispatch, useSelector } from "react-redux"
const Deposite = ({ formData, onSecurityDepositChange, onLastDateToSubmitChange, onDepositAmountChange }) => {
  const { properties } = db;
  const dispatch = useDispatch()
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty or undefined date
    return dateString.split("T")[0]; // Extracts the date part
  };

  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="h-[10%] text-center">
        <ParagraphTag
          content="Security deposit"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Will you be holding a security deposit on this lease?"
          classes="text-darkColor/50 font-popinsMedium text-xs"
        />
      </div>

      <div className="overflow-y-scroll h-[80%] panelScroll flex flex-col items-center pb-5">
        <div className="flex w-[40%] mx-auto justify-between mt-6">
          <div
            key={"Yes"}
            className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
            onClick={(e) => {
              e.preventDefault();
              onSecurityDepositChange(true)
            }}
          >
            <IconCards
              mainClasses={`h-36 w-[130px] ${formData?.securityDeposit ? "border-red-500 border-2" : ""
                }`}
              imgClasses="w-[40px]"
              path={
                "/assets/daashboard/create-propety/check.png"
              }
              title={"Yes"}
              titleClasses={
                "text-themeColor"
              }
            />
          </div>


          <div
            key={"No"}
            className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
            onClick={(e) => {
              e.preventDefault();
              onSecurityDepositChange(false)
            }}
          >
            <IconCards
              mainClasses={`h-36 w-[130px] ${!formData?.securityDeposit ? "border-red-500 border-2" : ""
                }`}
              imgClasses="w-[40px]"
              path={
                "/assets/daashboard/create-propety/close.png"
              }
              title={"No"}
              titleClasses={
                "text-themeColor"
              }
            />
          </div>


        </div>
        {
          formData?.securityDeposit && <div className="flex justify-between">
            <div className="w-[49%] mt-4">
              <LabelTag
                isStaric={false}
                name="Last date to submit"
                classes="!text-xs !font-popinsMedium"
              />

              <input
                value={formatDate(formData.lastDayToSubmitDeposit)}
                onChange={onLastDateToSubmitChange}
                type="date"
                placeholder="Type"
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
            <div className="w-[49%] mt-4">
              <LabelTag
                isStaric={false}
                name="Deposit amount"
                classes="!text-xs !font-popinsMedium"
              />
              <input
                value={formData.securityDepositAmount}
                onChange={onDepositAmountChange}
                type="number"
                placeholder="Type"
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
          </div>
        }




      </div>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(LeaseAction.ChangePropertyCreateSteps(5))
            }}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Deposite;
