import {

    LeaseStepActionConst,
    LeaseDetailActionConst,
    ClearAllLeaseDetails
} from "../../constants/LeaseActionConst"
import BaseUrl from "../../../constants/BaseUrl";


import { ShowSuccess } from "../../../utils/ToasFunction"




export const ChangePropertyCreateSteps = (step) => {
    return async dispatch => {
        dispatch({
            type: LeaseStepActionConst.LEASE_STEP_CHANGE,
            step: step
        })
    }
}


export const LeaseDetailAction = (data) => {
    return async dispatch => {
        dispatch({
            type: LeaseDetailActionConst.LEASE_DETAIL_DATA,
            data: data
        })
    }
}

export const ClearAllLeaseDetailsAction = () => {
    return async dispatch => {
        dispatch({
            type: ClearAllLeaseDetails.CLEAR_ALL_LEASE_DETAIL,

        })
    }
}






