import { LoginActionConst, SignUpActionConst, ClearSignUpFieldsActionConst, GetLandlordProfileActionConst, ProfileCompletedActionConst, LogoutActionConst } from "../../constants/authActionConst"
import BaseUrl from "../../../constants/BaseUrl";
import { ShowSuccess } from "../../../utils/ToasFunction";


export const UserLogInAction = fields => {
    return async dispatch => {
        dispatch({
            type: LoginActionConst.USER_LOGIN_REQUEST
        })

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email": fields.email,
            "password": fields.password
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${BaseUrl}landlord/auth/signIn`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "Success") {
                    dispatch({
                        type: LoginActionConst.USER_LOGIN_SUCC,
                        result: result
                    })
                } else {
                    dispatch({
                        type: LoginActionConst.USER_LOGIN_FAIL,
                        result: result
                    })
                }
            })
            .catch((error) => console.error(error));


    };
};

export const ClearSignUpFieldsAction = () => {
    return async dispatch => {
        dispatch({
            type: ClearSignUpFieldsActionConst.SIGNUP_CLEAR,
        })
    }
}


export const LogoutAction = () => {
    return async dispatch => {
        dispatch({
            type: LogoutActionConst.LOGOUT_ACTION,
        })
    }
}



export const GetLandlordProfile = (userId, check) => {



    return async dispatch => {
        dispatch({
            type: GetLandlordProfileActionConst.GET_USER_PROFILE_REQ
        })

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(`${BaseUrl}landlord/profile/getProfileDetails/${userId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status === "Success") {

                    dispatch({
                        type: GetLandlordProfileActionConst.GET_USER_PROFILE_SUCCESS,
                        result: result.data,

                    })
                } else {
                    // alert("ERROR=", JSON.stringify(result))
                    dispatch({
                        type: GetLandlordProfileActionConst.GET_USER_PROFILE_FAIL,
                        result: result.message
                    })
                }
            })
            .catch((error) => console.error(error));

        return








    }
}


export const UserSignUpAction = fields => {
    return async dispatch => {
        dispatch({
            type: SignUpActionConst.USER_SIGNUP_REQUEST
        })

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "firstName": fields.firstName,
            "lastName": fields.lastName,
            "email": fields.email,
            "password": fields.password,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${BaseUrl}landlord/auth/signUp`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "Success") {
                    dispatch({
                        type: SignUpActionConst.USER_SIGNUP_SUCC,
                        result: result
                    })
                } else {
                    dispatch({
                        type: SignUpActionConst.USER_SIGNUP_FAIL,
                        result: result
                    })
                }
            })
            .catch((error) => console.error(error));


    };
};





