import React, { useState } from "react";
import Breadcrumbs from "../../reusebale/breadcrumbs";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";
import db from "../../../db/index.json";
import ReactPaginate from "react-paginate";
import { Grid2x2 } from "lucide-react";

const Notification = ({ onBack }) => {
  const { notification } = db;
  const [data, setData] = useState([
    {
      id: 1,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 2,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 3,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 4,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 5,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 6,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 7,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 8,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 9,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 10,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 11,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 12,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 13,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
    {
      id: 14,
      titleSmall: "Lease Renewal",
      heading: "Your request have been submitted successfully",
      description: "Oct 25, 2024 6:03 am",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [switchLayout, serSwitchLayout] = useState(false);
  const itemsPerPage = 10;

  const paginatedData = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleToggle = () => {
    serSwitchLayout((prevState) => !prevState); // वैल्यू को true/false के बीच टॉगल करें
  };

  return (
    <div className="w-full h-screen">
      <div className="h-[10vh] flex items-center">
        <Breadcrumbs
          classes={`text-darkColor font-popinsSemiBold text-xl`}
          content={notification.title}
          onBack={onBack}
          subheading={notification.subTitle}
        />
      </div>
      <div className="h-[90vh]">
        <div className="bg-white rounded-xl h-[87%]">
          <div className="w-[95%] mx-auto pt-5 h-full">
            <div className="flex justify-between h-[12%]">
              <ParagraphTag
                content={notification.heading}
                classes={`text-darkColor font-popinsSemiBold text-lg `}
              />
              <button className="border-[#6F6F6F]/70 border rounded-md flex items-center justify-center px-4 h-8 text-xs font-popinsMedium text-[#6F6F6F]" onClick={handleToggle}>
                <Grid2x2 color="#6F6F6F" size={15} />
                <span className="pl-2">Switch layout</span>
              </button>
            </div>
            <div className="overflow-scroll w-full h-[88%] panelScroll">
              <div className="flex flex-wrap justify-between h-full">
                {paginatedData.map((item, i) => (
                  <div className={`${switchLayout ? 'w-full' : 'w-[49%]'} my-2 py-2 bg-slate-50 flex pl-4 items-center rounded-md`}>
                    <ImageTag
                      classes={`w-[38px] h-[38px]`}
                      path={"/assets/daashboard/notification-color.png"}
                      altText="logo"
                    />
                    <div className="px-2">
                      <ParagraphTag
                        content={item.titleSmall}
                        classes={`text-orangeColor font-popinsSemiBold text-xs `}
                      />
                      <ParagraphTag
                        content={item.heading}
                        classes={`text-darkColor font-popinsSemiBold text-sm `}
                      />

                      <ParagraphTag
                        content={item.description}
                        classes={`text-darkColor/40 font-popinsregular text-xs `}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center h-[13%]">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            pageCount={Math.ceil(data.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName="flex space-x-2 items-center"
            pageClassName="px-3 py-1 border rounded-md text-sm font-popinsMedium cursor-pointer"
            previousClassName="px-3 py-1 border rounded-l-md text-sm cursor-pointer font-popinsMedium"
            nextClassName="px-3 py-1 border rounded-r-md text-sm font-popinsMedium cursor-pointer font-popinsMedium"
            disabledClassName="opacity-50 !cursor-not-allowed"
            activeClassName="bg-themeColor text-white"
            breakClassName="px-3 py-1 text-sm font-popinsMedium"
          />
        </div>
      </div>
    </div>
  );
};

export default Notification;
