import React, { useState, useEffect } from "react";
import OverviewRequest from "./overviewRequest";
import Chat from "./chat";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import BaseUrl from "../../../../constants/BaseUrl";
import TenantInfo from "../../leases/leaseDetail/tenantInfo";
import CompanyInfo from "../../leases/leaseDetail/company-info";
const DetailMain = ({ onBack, recordId }) => {

  const [details, setDetails] = useState()
  const [tenantInfo, setTenantInfo] = useState()

  const GetTenantProfile = (id) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}tenant/profile/getProfileDetails/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result.status === "Success") {
          setTenantInfo(result.data)
        }

      })
      .catch((error) => console.error(error));
  }
  const GetRecordDetails = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/maintenence/getMaintanenceRequestDetails/${recordId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          GetTenantProfile(result?.data?.tenantId)
          setDetails(result?.data)
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    if (recordId) {
      GetRecordDetails()
    }
  }, [recordId])


  useEffect(() => {

    const interval = setInterval(() => {
      GetRecordDetails()
    }, 3000);


    return () => clearInterval(interval);
  }, []);





  const ChatUpdateHandler = (info) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      recordId: details?._id,
      sender: info.sender,
      text: info.text,
      senderType: info.senderType,
      time: info.time,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}api/maintenence/updateMaintenanceConversation`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result.status == 200) {
          GetRecordDetails()
        }
      })
      .catch((error) => console.error(error));
  };






  return (
    <div className="mt-4 h-full">
      <Breadcrumbs
        classes={`text-darkColor font-popinsSemiBold text-xl`}
        subheading="Maintenance Details"
        content={`MAIN-${details?._id?.slice(0, 10)}`}
        onBack={onBack}
      />
      <div className="flex justify-between mt-4 h-full">
        <div className="w-[64%] h-screen overflow-scroll scrollbar-hide panelScroll">
          <OverviewRequest onRehit={GetRecordDetails} tenantInfo={tenantInfo} data={details} />
        </div>
        <div className="w-[34%] h-full">
          <Chat chatUpdateHandler={ChatUpdateHandler} recordDetails={details} />
        </div>
      </div>
    </div>
  );
};

export default DetailMain;
