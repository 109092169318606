import React, { useRef, useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import { Camera } from "lucide-react";
import BaseUrl from "../../../../constants/BaseUrl";
import MyLoader from "../../../reusebale/myLoader";
import { useSelector } from "react-redux";
import { ShowError } from "../../../../utils/ToasFunction";
const UploadImage = (props) => {
  const { userCompleteProfile } = useSelector((state) => state.auth)

  // Create a ref for the input element
  const fileInputRef = useRef(null);

  // Function to handle click on the Camera icon
  const handleIconClick = () => {
    if (userCompleteProfile?.approvedStatus === "sendForApproval") {
      ShowError("Your profile request is pending for approval")
      return
    } else
      if (fileInputRef.current) {
        fileInputRef.current.click(); // Trigger input file click
      }
  };

  // Function to handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      props.imageUploadFunction(file)



      // console.log("Selected file:", file);
      // You can handle the file upload logic here
    }
  };

  return (
    <div className="bg-white rounded-xl">
      <div className="flex flex-col justify-center items-center py-5">
        <ParagraphTag
          content={props.imageTitle}
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />
        <ParagraphTag
          content="Max 1MB"
          classes="text-darkColor/40 font-popinsMedium text-xs "
        />
        <div className="w-full relative flex flex-col items-center justify-center my-3">
          <ImageTag
            path={
              props.imagePath
                ? props.imagePath
                : "/assets/daashboard/profile-complete/user-icon.png"
            }
            classes="w-[220px] h-[220px] object-cover rounded-full"
            altText="logo"
          />
          <div className="absolute bottom-[-15px]">
            <div className="bg-themeColor w-10 h-10 rounded-full flex justify-center items-center cursor-pointer" onClick={handleIconClick}>
              {
                props.loading ? <MyLoader /> : <Camera color="white" className="" size={22} />
              }

            </div>
            <input
              type="file"
              ref={fileInputRef} // Attach ref to input
              style={{ display: "none" }} // Hide input
              onChange={handleFileChange} // Handle file selection
              accept="image/*" // Optional: Restrict to image files only
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
