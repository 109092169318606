import React from "react";
import ParagraphTag from "../reusebale/Paragraph";
import ButtonTag from '../reusebale/button';

const UnitDetailPopup = ({ onBack, selectedUnit }) => {
  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[50%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col">
          <div>
            <ParagraphTag
              content={`Unit Details`}
              classes={`text-darkColor font-popinsSemiBold text-lg `}
            />
          </div>
          <div className="flex flex-wrap w-[90%] mx-auto" id="gk-cards">
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Unit Name"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.unitName}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Total buildings in the park"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.totalBuilding}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Rent"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.rent}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Rent Type"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.rentType}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Offices (%)"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.offices}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Bathrooms"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.bathrooms}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Size"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.size}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Building depth (%)"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.buildingDepth}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Clear height (%)"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.clearHeight}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Parking Ratio"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.parkingRation}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Sprinklers"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.sprinklers}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Water (Acres)"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.water}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Electric (%)"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.electric}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Grade level doors"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.gradeLevelDoors}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="# of Suites (%)"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.numberOfSuites}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Loading docks #"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.lodingDocks}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Tenant size (Acres)"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.tenantSize}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="Year built"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.yearBuild}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
            <div className="w-[32%] pt-4 mr-[2%]">
              <ParagraphTag
                content="HVAC"
                classes={`text-darkColor font-popinsSemiBold text-sm `}
              />
              <ParagraphTag
                content={selectedUnit?.hvacValue}
                classes={`text-darkColor/50 font-popinsMedium text-xs `}
              />
            </div>
          </div>
          <div className="w-[50%] mx-auto flex justify-center items-center">
            <ButtonTag
              onSubmit={onBack}
              name="Close"
              classes="text-sm text-center text-darkColor !w-[30%]"
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default UnitDetailPopup;
