import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";

const InfoSection = ({ detail }) => {


  return (
    <div className="bg-white mt-5 rounded-xl py-4 px-5">
      <ParagraphTag
        content="Broadcast Message Details"
        classes="text-darkColor font-popinsSemiBold text-lg"
      />
      <div className="pt-3">
        <ParagraphTag
          content="Title"
          classes={`text-darkColor font-popinsSemiBold text-sm `}
        />
        <ParagraphTag
          content={detail?.title}
          classes={`text-darkColor/40 font-popinsRegular text-xs `}
        />
        <ParagraphTag
          content="Description"
          classes={`text-darkColor font-popinsSemiBold text-sm pt-3`}
        />
        <ParagraphTag
          content={detail?.description}
          classes={`text-darkColor/40 font-popinsRegular text-xs `}
        />
      </div>
    </div>
  );
};

export default InfoSection;
