import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph"
import UnitsCard from "../../../reusebale/uniCard"
import db from "../../../../db/index.json";
import EditUnits from "../../../popup/editUnits";
const UnitsSection = ({ allDetails }) => {
  const { properties } = db;
  const [showUnitPopUp, setShowUnitpopUp] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState({ "id": 0, "unitName": "", "totalBuilding": "", "rent": "", "rentType": "", "offices": "", "bathrooms": "", "size": "", "buildingDepth": "", "clearHeight": "", "parkingRation": "", "sprinklers": "", "water": "", "electric": "", "waltRollArea": "", "numberOfSuites": "", "lodingDocks": "", "gradeLevelDoors": "", "tenantSize": "", "yearBuild": "", "hvac": false, "hvacValue": "" })
  return (
    <div className="bg-white mt-5 rounded-xl">
      <div className="w-[95%] mx-auto pt-5 lg:max-h-[400px] 2xl:max-h-[300px] overflow-y-scroll panelScroll">
        <div className="flex justify-between">

          <ParagraphTag
            content='Units'
            classes={`text-darkColor font-popinsSemiBold text-lg `}
          />
        </div>
        <div className="flex flex-wrap justify-between mt-3">
          {allDetails?.unitData.map((item, i) => (
            <UnitsCard
              isDelete={!item.occupied ? true : false}

              occopied={item.occupied}
              onTrigger={() => {

                setSelectedUnit(item)
                setShowUnitpopUp(true)
              }}
              data={item} classes='w-[49%] my-2 py-3 px-2 cursor-pointer' />
          ))}
        </div>
      </div>
      {
        showUnitPopUp && <EditUnits
          selectedUnit={selectedUnit}

          onBack={() => {
            setShowUnitpopUp(false)
          }}
          unitNameValue={selectedUnit.unitName}
          buildingValue={selectedUnit?.totalBuilding}
          rentValue={selectedUnit?.rent}
          rentTypeValue={selectedUnit.rentType}
          officesValue={selectedUnit.offices}
          bathroomsValue={selectedUnit.bathrooms}
          sizeValue={selectedUnit.size}
          buildingDepthValue={selectedUnit.buildingDepth}
          clearHeightValue={selectedUnit.clearHeight}
          parkingRationValue={selectedUnit.parkingRation}
          sprinklersValue={selectedUnit.sprinklers}
          waterValue={selectedUnit.water}
          electricValue={selectedUnit.electric}
          waltRollAreaValue={selectedUnit.waltRollArea}
          noOfSuitsValue={selectedUnit.numberOfSuites}
          loadingDocksValue={selectedUnit.lodingDocks}
          gradeLevelDoorsValue={selectedUnit.gradeLevelDoors}
          yearBuildValue={selectedUnit.yearBuild}
          tenantsSizeValue={selectedUnit.tenantSize}
          hvacValue={selectedUnit.hvac}
          hvacNumberValue={selectedUnit.hvacValue}
        />
      }
    </div>

  );
};

export default UnitsSection;
