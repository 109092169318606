import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import { ArrowRight } from "lucide-react";
import NoData from '../../reusebale/noData'
import ImageTag from "../../reusebale/imageTag";
import BaseUrl from "../../../constants/BaseUrl";
import { useSelector } from "react-redux";
import Myloader from "../../reusebale/myLoader"

const PropertiesSection = ({onViewDetails, onPropertyPage}) => {
  const { rightPanel } = db;
  const [allRequestes, setAllRequests] = useState([]);
  const { loginSuccess } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const GetMaintenenceRequest = () => {
    setLoader(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/property/getPropertiesByLanloard/${loginSuccess.user._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setLoader(false);
          setAllRequests(result.data);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    GetMaintenenceRequest();
  }, []);

  return (
    <div className="bg-white rounded-2xl py-5 px-4 w-full mt-5 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="flex justify-between items-center ">
        <ParagraphTag
          content={rightPanel.properties}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <ParagraphTag
          content={rightPanel.viewall}
          classes="text-themeColor font-popinsSemiBold text-sm cursor-pointer"
          onTrigger={onPropertyPage}
        />
      </div>
      <div className="flex border-b-[1px] border-darkColor/40 pb-1 mt-6">
        <div className="w-[45%]">
          <ParagraphTag
            content={"Property"}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[12%]">
          <ParagraphTag
            content={"Units"}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[18%] text-end">
          <ParagraphTag
            content={"Occ. Unit"}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[24%] text-end">
          <ParagraphTag
            content={rightPanel.quick}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
      </div>
      <div className="h-[610px] overflow-y-scroll panelScroll">
        {allRequestes?.map((item, i) => (
          <div className="flex mt-4 justify-between items-center">
            <div className="w-[45%] flex items-center">
              <div className="w-[25%] rounded-full">
                <ImageTag
                  path={item?.thumbnailImage?.url}
                  classes="xl:w-[45px] xl:h-[45px] 2xl:w-[50px] 2xl:h-[50px] rounded-full"
                  altText="login"
                />
              </div>
              <div className="w-[75%] pl-1">
                <ParagraphTag
                  content={`PROP-${item?._id?.slice(0, 10)}`}
                  classes="text-darkColor/40 font-popinsMedium text-xs"
                />
                <ParagraphTag
                  content={item?.propertyName}
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
            <div className="w-[20%]">
              <ParagraphTag
                content={item?.unitCount}
                classes="text-darkColor font-popinsMedium text-sm"
              />
            </div>
            <div className="w-[14%] pl-2">
              <ParagraphTag
                content={item?.leaseUnitCount}
                classes="text-darkColor font-popinsMedium text-sm"
              />
            </div>
            <div className="w-[19%] flex justify-center items-center">
              <div className="flex justify-center items-center bg-themeColor w-[35%] h-8 rounded-lg cursor-pointer" onClick={() => {
                              onViewDetails(item._id);
                            }}>
                <ArrowRight color="#FFFF" size={20} strokeWidth={2} />
              </div>
            </div>
          </div>
        ))}
        {loader && (
          <div className="flex justify-center items-center w-full mt-20">
            <Myloader />
          </div>
        )}
         {allRequestes.length === 0 && (
          <div className="w-full mt-5 flex justify-center">
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertiesSection;
