import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";

const LandlordInfo = ({ data }) => {

  let personalInformation = data?.personalInformation

  return (
    <div className="bg-white rounded-xl p-2">
      <div className="px-4 pt-4">
        <ParagraphTag
          content="Tenant's Personal Information"
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />
      </div>
      <div className="flex mt-1">

        <div className="w-[70%] px-2">
          <div className="pt-3">
            <ParagraphTag
              content="Full Name"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={`${personalInformation?.firstName}  ${personalInformation?.lastName}`}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-3">
            <ParagraphTag
              content="Email"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={`${personalInformation?.email}`}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-2 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="Phone Number"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={`${personalInformation?.phoneNumber}`}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default LandlordInfo;
