import React from "react";
import ParagraphTag from "../reusebale/Paragraph";
import LabelTag from "../reusebale/label";
import ButtonTag from '../reusebale/button';

const AppealPopup = ({ onReasonSubmit, onBack }) => {

  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[30%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col">
          <div>
            <ParagraphTag
              content="Reason"
              classes={`text-darkColor font-popinsSemiBold text-base `}
            />
          </div>
          <div className="pt-2 w-[90%] mx-auto">
            <LabelTag
              isStaric={false}
              name="Appeal"
              classes="!text-xs !font-popinsMedium"
            />
            <textarea
              type="text"
              cols={10}
              placeholder="Please enter your reason"
              className="w-full h-28 mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            ></textarea>
          </div>
        <div className="w-full px-2">
            <ButtonTag onSubmit={onReasonSubmit} name='Submit' classes='text-sm bg-themeColor hover:bg-themeColor/90 mt-5 text-center  text-white' />
            <p className="text-darkColor pt-2 flex item-center justify-center cursor-pointer font-popinsMedium text-sm" onClick={onBack}>Close</p>
        </div>

        </div>
      </section>
    </main>
  );
};

export default AppealPopup;
