import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import db from "../../../../db/index.json";

const Recipent = ({ detail }) => {
  const { broadcasts } = db;

  return (
    <div className="bg-white mt-4 rounded-xl py-4 px-5">
      <ParagraphTag
        content="Tenants"
        classes="text-darkColor font-popinsSemiBold text-lg"
      />
      <div className="flex justify-between flex-wrap mt-5">
        {detail?.tenantList?.map((item) => (
          <div className="w-[24%] flex  bg-yellow-100">

            <div className="text-center pt-2 px-1">
              <ParagraphTag
                content={`TEN-${(item?._id)?.slice(0, 10)}`}
                classes="text-darkColor/50 font-popinsMedium text-xs"
              />
              <ParagraphTag
                content={`${item?.firstName} ${item?.lastName} `}
                classes="text-darkColor font-popinsSemiBold text-sm mt-[-2px]"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recipent;
