
import { LeaseStepActionConst, LeaseDetailActionConst, ClearAllLeaseDetails } from "../../constants/LeaseActionConst"
const initialState = {
    loader: false,
    allLease: [],
    leaseCreateStep: 1,
    leaseData: null,
    leaseUpdate: false
}
export default (state = initialState, action) => {
    switch (action.type) {
        case LeaseStepActionConst.LEASE_STEP_CHANGE:
            state = {
                ...state,
                leaseCreateStep: action.step
            }
            break

        case LeaseDetailActionConst.LEASE_DETAIL_DATA:
            state = {
                ...state,
                leaseData: action.data,
                leaseUpdate: true
            }
            break

        case ClearAllLeaseDetails.CLEAR_ALL_LEASE_DETAIL:
            state = {
                ...state,
                leaseData: null,
                leaseUpdate: false
            }
            break


    }
    return state

}