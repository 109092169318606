import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";
import db from "../../../db/index.json";
import { Plus } from "lucide-react";
import ReactPaginate from "react-paginate";
import BaseUrl from "../../../constants/BaseUrl";
import { useSelector } from "react-redux";
import moment from "moment";
const BroadCastSection = ({ onViewDetails, onViewCreate }) => {
  const { broadcasts } = db;
  const { loginSuccess, } = useSelector(
    (state) => state.auth
  );


  const [data, setData] = useState([
    {
      id: 1,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 2,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 3,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 4,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 5,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 6,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 7,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 8,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 9,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 10,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 11,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 12,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 13,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
    {
      id: 14,
      heading:
        "Pellentesque suscipit fringilla cent libero eu ullamcorper. Cras risus eros, faucibus sit amet augue id..",
      description: "Nov 21, 2024 10:43 pm",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const paginatedData = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };


  const [allData, setAllData] = useState([])
  const GetBroadcastData = () => {


    const requestOptions = {
      method: "GET",

      redirect: "follow"
    };

    fetch(`${BaseUrl}api/broadcast/getAllBroadcastsByLandlord?landlordId=${loginSuccess.user._id}&page=1&limit=100`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllData(result?.data)
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    GetBroadcastData()
  }, [])


  return (
    <div className="h-full pt-4">
      <div className="bg-white w-full rounded-lg h-[87%]">
        <div className="w-[95%] mx-auto h-full">
          <div className="flex justify-between items-center h-[12%]">
            <div className="w-[70%]">
              <ParagraphTag
                content={broadcasts.heading}
                classes="text-darkColor font-popinsSemiBold text-lg"
              />
            </div>

            <div className="w-[30%] flex justify-end">
              <button className="w-[48%] bg-themeColor rounded-md flex items-center justify-center py-2" onClick={onViewCreate}>
                <Plus color="#FFFFFF" size={15} />
                <ParagraphTag
                  content="New Broadcasts"
                  classes="text-white font-popinsSemiBold text-xs pl-2"
                />
              </button>
            </div>
          </div>
          <div className="overflow-x-scroll w-full h-[88%] panelScroll pt-2">
            {allData?.map((item, i) => (
              <div className="flex cursor-pointer py-2" onClick={() => {
                onViewDetails(item)
              }}>
                <div className="w-[80%] py-2 flex items-center">
                  <ImageTag
                    classes={`w-[38px] h-[38px]`}
                    path={"/assets/daashboard/notification-color.png"}
                    altText="logo"
                  />
                  <div className="px-2">
                    <ParagraphTag
                      content={item.title}
                      classes={`text-darkColor font-popinsSemiBold text-xs `}
                    />
                    <ParagraphTag
                      content={item.description}
                      classes={`text-[#686868] font-popinsRegular text-sm text-[10px]`}
                    />
                  </div>
                </div>
                <div className="w-[20%]">
                  <div className="px-4 py-4">
                    <ParagraphTag
                      content={moment(item.createdAt).format("DD/MM/YY")}
                      classes={`text-darkColor font-popinsSemiBold text-xs `}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center h-[13%]">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          pageCount={Math.ceil(data.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName="flex space-x-2 items-center"
          pageClassName="px-3 py-1 border rounded-md text-sm font-popinsMedium cursor-pointer"
          previousClassName="px-3 py-1 border rounded-l-md text-sm cursor-pointer font-popinsMedium"
          nextClassName="px-3 py-1 border rounded-r-md text-sm font-popinsMedium cursor-pointer font-popinsMedium"
          disabledClassName="opacity-50 !cursor-not-allowed"
          activeClassName="bg-themeColor text-white"
          breakClassName="px-3 py-1 text-sm font-popinsMedium"
        />
      </div>
    </div>
  );
};

export default BroadCastSection;
