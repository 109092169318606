import React, { useState } from "react";
import ButtonTag from "../../../reusebale/button";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ImageTag from "../../../reusebale/imageTag";
import * as DataAction from "../../../../store/actions/data/DataAction"
import db from "../../../../db/index.json";
import { useSelector, useDispatch } from "react-redux"

const Credentials = ({ formFields, onTenantListUpdate }) => {
  const { broadcasts } = db;
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()

  const OnEmailChangeMode = (id) => {
    let updateList = [...formFields.tenantList]
    for (const key in updateList) {
      if (updateList[key]._id === id) {
        updateList[key].emailSelect = !updateList[key].emailSelect
        updateList[key].numberSelect = false
      }
    }
    onTenantListUpdate(updateList)
  }


  const OnNumberChangeMode = (id) => {
    let updateList = [...formFields.tenantList]
    for (const key in updateList) {
      if (updateList[key]._id === id) {
        updateList[key].emailSelect = false
        updateList[key].numberSelect = !updateList[key].numberSelect
      }
    }
    onTenantListUpdate(updateList)
  }

  return (
    <div className="w-[95%] mx-auto h-full">
      <div className="text-center h-[10%] flex flex-col justify-center">
        <ParagraphTag
          content="All Tenants"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
          <ParagraphTag
            content="You can select a notification mode for each tenant."
            classes="text-[#686868] font-popinsRegular text-xs"
          />
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className="w-[40%] mx-auto">
          <div className="mt-3">
            <LabelTag
              isStaric={false}
              name="Search Tenant"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              type="text"
              placeholder="Enter search here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full mt-5" id="gk-cards">
          {formFields?.tenantList?.map((item) => {
            if (item?.firstName?.includes(search) || item?.lastName?.includes(search)) {
              return (
                <div className="w-[23%] flex mt-1 mr-[2%]">
                  {/* <div className="w-[20%] rounded-full"> */}
                    {/* <ImageTag path={item.image} classes="w-full" altText="logo" /> */}
                  {/* </div> */}
                  <div className="text-center pt-2 px-1 ">
                    <ParagraphTag
                      content={`TEN-${(item?._id)?.slice(0, 10)}`}
                      classes="font-popinsRegular text-xs text-[#686868]"
                    />
                    <ParagraphTag
                      content={`${item?.firstName} ${item?.lastName}`}
                      classes="text-darkColor font-popinsSemiBold text-sm"
                    />
                    <div className="flex justify-between w-full pt-1">
                      <div className="flex w-[49%] items-center">
                        <input
                          checked={item?.emailSelect}
                          onChange={() => {
                            OnEmailChangeMode(item._id)
                          }}
                          type="checkbox"
                          className="w-3 h-3 bg-bgColor rounded-sm cursor-pointer"
                        />
                        <ParagraphTag
                          content="email"
                          classes="text-[#686868] text-xs pl-1 font-popinsRegular"
                        />
                      </div>
                      <div className="flex w-[49%] items-center justify-end">
                        <input
                          checked={item?.numberSelect}
                          onChange={() => {
                            OnNumberChangeMode(item._id)
                          }}
                          type="checkbox"
                          className="w-3 h-3 bg-bgColor rounded-sm cursor-pointer"
                        />
                        <ParagraphTag
                          content="SMS"
                          classes="text-[#686868] text-xs pl-1 font-popinsRegular"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(DataAction.ChangeBroadcastCurrentStep(3))
            }}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Credentials;
