import React from "react";
import ParagraphTag from "../reusebale/Paragraph";
import ButtonTag from '../reusebale/button';

const WarningPopup = ({ onDeleteItem, onClose }) => {
  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[20%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col">
         
          <ParagraphTag
            content='Are you sure you want to delete this?'
            classes=" text-darkColor  text-base pt-2"
          />
          <div className="w-[90%]">
            <ButtonTag name='Yes' onSubmit={onDeleteItem} classes=' mt-3 text-base text-center bg-themeColor text-white' />
            <ButtonTag name='No' onSubmit={onClose} classes=' mt-3 text-base text-center text-white bg-dangerColor' />
          </div>
        </div>
      </section>
    </main>
  );
};

export default WarningPopup;
