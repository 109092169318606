import React, { useEffect, useState } from "react";
import OverviewSection from "./overview";
import UnitsSection from "./units";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import MaintenanceRequestSection from "../../home/maintainance-request";
import ReceivePayment from "../../home/receivePayment";
import LeaseTableSection from "../property-detail/leaseSection";
import ImageCarousel from "../property-detail/image-carousel";
import db from "../../../../db/index.json";
import BaseUrl from "../../../../constants/BaseUrl";
import MyLoader from "../../../reusebale/myLoader";

const MainSection = ({ onBack, propertyId, onViewDetailsMaintain, onMaintainancePage, onCreateLease, toggleLeaseDetail }) => {
  const [allDetails, setAllDetails] = useState()
  const [loader, setLoader] = useState()
  const { properties } = db;



  const GetPropertyDetails = () => {
    setLoader(true)
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/property/getPropertyDetails/${propertyId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setLoader(false)
          setAllDetails(result.data)
        } else {
          setLoader(false)
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    GetPropertyDetails()
  }, [propertyId])

  if (loader) {
    return (
      <div className="flex h-screen justify-center items-center">
        <MyLoader/>
      </div>
    )
  } else {
    return (
      <div className="mb-8">
        <div className="mt-6 w-full">

          <Breadcrumbs
            classes={`text-darkColor font-popinsSemiBold text-lg`}
            content={`PROP-${allDetails?._id?.slice(1, 10)}`}
            subheading="Property Details"
            onBack={onBack}
          />
        </div>
        <OverviewSection allDetails={allDetails} />
        <ImageCarousel allDetails={allDetails}/>
        {/* <div className="flex flex-wrap justify-between mt-5">
          {properties.cardData.map((item, i) => (
            <InfoCards item={item} />
          ))}
        </div> */}

        <UnitsSection allDetails={allDetails} />
        <div className="flex justify-between mt-4">
          <div className="w-[49%]">
            <MaintenanceRequestSection onViewDetails={onViewDetailsMaintain} onMaintainanceTable={onMaintainancePage}/>
          </div>
          <div className="w-[49%]">
            <ReceivePayment />
          </div>
        </div>
        <LeaseTableSection createLease={onCreateLease} isViewDetails={toggleLeaseDetail} />
      </div>
    );
  }

};

export default MainSection;
