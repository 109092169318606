import React, { useState } from "react";
import SearchBar from "../../reusebale/searchBar";
import db from "../../../db/index.json";
import TenantsTable from "../tenants/tenantsTable";
import MainTenantSection from "../tenants/tenantsDetail/main";
import FilterPopup from "../../popup/filterPopup";
import DetailMain from "../maintenance/maintanance-detail/detailMain";
import CreateMaintainace from "../maintenance/create-maintainance/main";
import PersonalInfo from "../profile-complete/personal-information/personal-info";
import DetailLeaseMain from "../leases/leaseDetail/main";
import CreateMain from "../leases/createProperty/createMain";
import { useDispatch } from "react-redux";
import * as LeaseDataAction from "../../../store/actions/lease/LeaseAction"

const TenantsSection = ({ toggleMaintainanceTable, onTriggerCard, onBack }) => {
  const { tenants } = db;

  const [showTable, setShowTable] = useState("table"); // Default: Table show ho raha hai

  const [showFilter, setShowFilter] = useState(false);
  const [recordId, setRecordId] = useState();
  const [showLeaseDetail, setShowLeaseDetail] = useState("");
  const [leaseRecordId, setLeaseRecordId] = useState();
  const dispatch = useDispatch();

  const openPopup = () => {
    setShowFilter(true);
  };

  const closePopup = () => {
    setShowFilter(false);
  };

  const toggleDetail = (tenant) => {
    setSelectedTenant(tenant);
    setShowTable("detail");
  };

  const toggleDetailMaintain = () => {
    setShowTable("MaintainanceDetails");
  };

  const handleCreateMaintain = () => {
    setShowTable("createMaintainance"); // State toggle karein
  };

  const toggleDetailProperty = () => {
    setShowTable("detail");
  };

  const handleShowProfile = (item) => {
    setShowTable(item);
  };

  const toggleTable = () => {
    setShowTable("table"); // State toggle karein
  };

  const handleCreateLease = () => {
    setShowTable("createLease"); // State toggle karein
  };

  const leaseDetail = (status, id) => {
    setShowLeaseDetail(status);
    setLeaseRecordId(id);
    setShowTable("leasedetail"); // State toggle karein
  };

  const [seletedTenant, setSelectedTenant] = useState();

  return (
    <div>
      {(() => {
        switch (showTable) {
          case "table":
            return (
              <div className="h-screen w-full">
                <div className="h-[10vh]">
                  <SearchBar
                    title={tenants.title}
                    desc={tenants.desc}
                    onSubmit={openPopup}
                  />
                </div>
                <div className="h-[90vh]">
                  <TenantsTable onViewDetails={toggleDetail} />
                </div>
              </div>
            );
          case "detail":
            return (
              <MainTenantSection
                seletedTenant={seletedTenant}
                onBack={toggleTable}
                onViewMaintainDetails={(item) => {
                  setRecordId(item);
                  toggleDetailMaintain(item);
                }}
                onViewMaintainCreate={handleCreateMaintain}
                onAction={(item) => {
                  handleShowProfile(item);
                }}
                toggleLeaseDetail={leaseDetail}
                onTriggerCard={onTriggerCard}
                toggleCreateLease={handleCreateLease}
              />
            );
          case "MaintainanceDetails":
            return (
              <DetailMain
                recordId={recordId._id}
                onBack={toggleDetailProperty}
              />
            );
          case "createMaintainance":
            return <CreateMaintainace onBack={toggleTable} />;
          case "General Info":
            return <PersonalInfo clases="mt-5" onBack={toggleDetailProperty} />;
          case "createLease":
            return <CreateMain clases="mt-5" onBack={onBack}/>;
          case "leasedetail":
            return (
              <DetailLeaseMain
                onBack={toggleTable}
                showDetailStatus={showLeaseDetail}
                recordId={leaseRecordId}
                onViewDetailsMaintain={(item) => {
                  setRecordId(item);
                  toggleDetailMaintain(item);
                }}
                onEditLease={(leaseDetails) => {
                  handleCreateLease();
                  dispatch(LeaseDataAction.LeaseDetailAction(leaseDetails));
                }}
                onMaintainancePage={toggleMaintainanceTable}
                onTriggerCard={onTriggerCard}
              />
            );
          default:
            break;
        }
      })()}

      {showFilter && <FilterPopup onBack={closePopup} onFilter={closePopup} />}
    </div>
  );
};

export default TenantsSection;
