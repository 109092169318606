import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import InfoCards from "../../../reusebale/cards";
import LeaseInfo from "./leaseInfo";
import TenantInfo from "./tenantInfo";
import PropertyInfo from "./propertyInfo";
import db from "../../../../db/index.json";
import MaintenanceRequestSection from "../../home/maintainance-request";
import ReceivePayment from "../../home/receivePayment";
import Chat from "../../leases/leaseDetail/chat";
import RentBreakDown from "./rentBreakDown";
import ButtonTag from "../../../reusebale/button";
import BaseUrl from "../../../../constants/BaseUrl";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import Agreement from "../../../template/AgreementSample";
import { ArrowBigDown } from "lucide-react";
import ImageTag from "../../../reusebale/imageTag";
import ParagraphTag from "../../../reusebale/Paragraph";
import LandlordInfo from "./landrlordInfo";
import CompanyInfo from "./company-info";

const DetailLeaseMain = ({ onBack, showDetailStatus, recordId, onViewDetailsMaintain, onMaintainancePage, onTriggerCard, onEditLease }) => {
  const { lease } = db;

  const [leaseDetails, setLeaseDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tenantInfo, setTenantInfo] = useState()


  const GetTenantProfile = (id) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}tenant/profile/getProfileDetails/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result.status === "Success") {
          setTenantInfo(result.data)
        }

      })
      .catch((error) => console.error(error));
  }


  const GetLeaseDetails = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}api/lease/getLeaseDetails/${recordId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          GetTenantProfile(result.data.tenantInfo._id)
          setLoading(false);
          setLeaseDetails(result.data);
        } else {
          ShowSuccess(result.message);
          setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      GetLeaseDetails();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const ChatUpdateHandler = (info) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      leaseId: leaseDetails?._id,
      sender: info.sender,
      text: info.text,
      senderType: info.senderType,
      time: info.time,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}api/lease/updateLeaseConversation`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200) {
          GetLeaseDetails();
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <div className="mt-5 w-full parent">
        <Breadcrumbs
          classes={`text-darkColor font-popinsSemiBold text-xl`}
          content={`LE-${leaseDetails?._id?.slice(0, 10)}`}
          subheading="Lease Details"
          onBack={onBack}
        />
        {showDetailStatus === "active" ? (
          <div className="mb-5">
            <div className="flex h-full justify-between mt-5">
              <div className="w-[64%]  rounded-lg h-full">

                <div className="my-5">
                  {
                    tenantInfo && <CompanyInfo data={tenantInfo} />
                  }

                </div>
                <div className="h-[400px] overflow-y-scroll panelScroll my-5 bg-white">
                  <Agreement
                    tenentInfo={leaseDetails?.tenantInfo}
                    data={leaseDetails}
                  />
                </div>
                <div className="h-[100px]">
                  <div className="bg-[#FAF2EC] flex px-5 h-full items-center rounded-lg">
                    <div className="w-[50%] flex items-center">
                      <ImageTag
                        path="/assets/daashboard/pdf.png"
                        classes="w-[50px] h-[45px]"
                        altText="logo"
                      />
                      <div className="pl-2">
                        <ParagraphTag
                          content="Signed Leases 2024.pdf"
                          classes="text-darkColor font-popinsSemiBold text-xs"
                        />
                        <ParagraphTag
                          content="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec."
                          classes="text-darkColor font-popinsMedium text-[10px] line-clamp-2 pt-[2px]"
                        />
                        <div className="flex pt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                          >
                            <path
                              d="M3.20915 1.01507C3.20915 0.60703 3.54519 0.246997 3.97723 0.246997C4.38526 0.246997 4.7453 0.60703 4.7453 1.01507V1.78314H7.81758V1.01507C7.81758 0.60703 8.15362 0.246997 8.58566 0.246997C8.99369 0.246997 9.35373 0.60703 9.35373 1.01507V1.78314H10.5058C11.1299 1.78314 11.6579 2.31119 11.6579 2.93525V4.08736H0.90494V2.93525C0.90494 2.31119 1.40899 1.78314 2.05705 1.78314H3.20915V1.01507ZM11.6579 4.85543V11.384C11.6579 12.0321 11.1299 12.5361 10.5058 12.5361H2.05705C1.40899 12.5361 0.90494 12.0321 0.90494 11.384V4.85543H11.6579Z"
                              fill="#FF814E"
                            />
                          </svg>
                          <ParagraphTag
                            content="10/09/2023"
                            classes="text-darkColor/40 font-popinsRegular text-xs pl-1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-[50%] flex justify-end items-center">
                      <button className="border-2 border-themeColor flex justify-center items-center px-4 h-9 rounded-lg">
                        <ArrowBigDown color="#FF814E" size={20} />
                        <span className="text-themeColor font-popinsRegular text-xs pl-1">
                          Download Document
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[34%] h-full">
                <div className="bg-white rounded-lg px-5">
                  <RentBreakDown leaseDetails={leaseDetails} />
                </div>
                <div className="mt-4">
                  <TenantInfo leaseDetails={leaseDetails} />
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-5">
              <div className="w-full">
                <PropertyInfo leaseDetails={leaseDetails} />
              </div>
            </div>
            <div className="flex justify-between mt-5">
              <div className="w-[49%]">
                <MaintenanceRequestSection onViewDetails={onViewDetailsMaintain} onMaintainanceTable={onMaintainancePage} />
              </div>
              <div className="w-[49%]">
                <ReceivePayment leaseDetails={leaseDetails} />
              </div>
            </div>
            <div className="flex flex-wrap mt-5" id="gk-cards">
              {lease.cardData2.map((item, i) => (
                <InfoCards item={item} leaseDetails={leaseDetails} onTrigger={onTriggerCard} />
              ))}
            </div>
          </div>
        ) : (
          <div className="h-screen">
            <div className="flex justify-between h-full">
              <div className="w-[59%] h-screen overflow-scroll scrollbar-hide panelScroll py-5">
                {
                  tenantInfo && <LandlordInfo data={tenantInfo} />
                }

                <div className="mt-5">
                  {
                    tenantInfo && <CompanyInfo data={tenantInfo} />
                  }

                </div>
                <div className="mt-5">
                  <PropertyInfo leaseDetails={leaseDetails} />
                </div>
                <div className="mt-5 bg-white rounded-lg">
                  <div className="h-[400px] overflow-y-scroll panelScroll mb-5">
                    <Agreement
                      tenentInfo={leaseDetails?.tenantInfo}
                      data={leaseDetails}
                    />
                  </div>
                  <div className="h-[100px]">
                    <div className="bg-[#FAF2EC] flex px-5 h-full items-center rounded-lg">
                      <div className="w-[50%] flex items-center">
                        <ImageTag
                          path="/assets/daashboard/pdf.png"
                          classes="w-[50px] h-[45px]"
                          altText="logo"
                        />
                        <div className="pl-2">
                          <ParagraphTag
                            content="Signed Leases 2024.pdf"
                            classes="text-darkColor font-popinsSemiBold text-xs"
                          />
                          <ParagraphTag
                            content="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec."
                            classes="text-darkColor font-popinsMedium text-[10px] line-clamp-2 pt-[2px]"
                          />
                          <div className="flex pt-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                            >
                              <path
                                d="M3.20915 1.01507C3.20915 0.60703 3.54519 0.246997 3.97723 0.246997C4.38526 0.246997 4.7453 0.60703 4.7453 1.01507V1.78314H7.81758V1.01507C7.81758 0.60703 8.15362 0.246997 8.58566 0.246997C8.99369 0.246997 9.35373 0.60703 9.35373 1.01507V1.78314H10.5058C11.1299 1.78314 11.6579 2.31119 11.6579 2.93525V4.08736H0.90494V2.93525C0.90494 2.31119 1.40899 1.78314 2.05705 1.78314H3.20915V1.01507ZM11.6579 4.85543V11.384C11.6579 12.0321 11.1299 12.5361 10.5058 12.5361H2.05705C1.40899 12.5361 0.90494 12.0321 0.90494 11.384V4.85543H11.6579Z"
                                fill="#FF814E"
                              />
                            </svg>
                            <ParagraphTag
                              content="10/09/2023"
                              classes="text-darkColor/40 font-popinsRegular text-xs pl-1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-[50%] flex justify-end items-center">
                        <button className="border-2 border-themeColor flex justify-center items-center px-4 h-9 rounded-lg">
                          <ArrowBigDown color="#FF814E" size={20} />
                          <span className="text-themeColor font-popinsRegular text-xs pl-1">
                            Download Document
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-lg mt-5">
                  <RentBreakDown leaseDetails={leaseDetails} />
                </div>
                <div className="pt-4">
                  <ButtonTag
                    onSubmit={() => {
                      onEditLease(leaseDetails)
                    }}
                    name="Modify Lease"
                    classes="text-sm text-center bg-themeColor text-white"
                  />
                </div>
              </div>

              <div className="w-[39%] pt-5 h-full">
                <Chat
                  chatUpdateHandler={ChatUpdateHandler}
                  leaseDetails={leaseDetails}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailLeaseMain;
