import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import { UserRound, Mail, Phone, CalendarPlus } from "lucide-react";
import moment from "moment";

const InfoSection = ({ tenantInfo }) => {
  return (
    <div className="bg-white rounded-xl mt-6">
      <div className="flex mt-1 items-center py-8">
        {/* <div className="w-[30%] flex justify-center">
          <ImageTag
            path="/assets/daashboard/company-info-Round.png"
            classes="w-[135px] rounded-full"
            altText="logo"
          />
        </div> */}
        <div className="w-[90%] mx-auto">
          <div>
            <ParagraphTag
              content="Tenant Info"
              classes="text-darkColor font-popinsSemiBold text-lg"
            />
          </div>
          <div className="pt-7 flex w-full">
            <div className="w-[50%] flex items-end">
              <div className="w-9 h-9 rounded-full flex justify-center items-center bg-bgColor">
                <UserRound color="#FF814E" strokeWidth={2} size={20}/>
              </div>
              <div className="pl-2">
                <ParagraphTag
                  content="Name"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
                <ParagraphTag
                  content={`${tenantInfo?.personalInformation?.firstName} ${tenantInfo?.personalInformation?.lastName}`}
                  classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                />
              </div>
            </div>
            <div className="w-[50%] flex items-center">
              <div className="w-9 h-9 rounded-full flex justify-center items-center bg-bgColor">
                <Mail color="#FF814E" strokeWidth={2} size={20}/>
              </div>
              <div className="pl-2">
                <ParagraphTag
                  content="Email Address"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
                <ParagraphTag
                  content={`${tenantInfo?.personalInformation?.email}`}
                  classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                />
              </div>
            </div>
          </div>
          <div className="pt-7 flex">
            <div className="w-[50%] flex items-center">
              <div className="w-9 h-9 rounded-full flex justify-center items-center bg-bgColor">
                <Phone color="#FF814E" strokeWidth={2} size={20}/>
              </div>
              <div className="pl-2">
                <ParagraphTag
                  content="Phone"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
                <ParagraphTag
                  content={`${tenantInfo?.personalInformation?.phoneNumber}`}
                  classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                />
              </div>
            </div>
            <div className="w-[50%] flex items-center">
              <div className="w-9 h-9 rounded-full flex justify-center items-center bg-bgColor">
                <CalendarPlus color="#FF814E" strokeWidth={2} size={20}/>
              </div>
              <div className="pl-2">
                <ParagraphTag
                  content="Create at"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
                <ParagraphTag
                  content={moment(tenantInfo?.createdAt).format("DD/MM/YYYY")}
                  classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
