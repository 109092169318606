import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import moment from "moment";
import {
  Plus,
  History,
  ArrowUpDown,
  ArrowDownUp,
  CircleCheck,
  Clock4,
  ArrowRight,
  CircleAlert,
} from "lucide-react";
import db from "../../../../db/index.json";
import NoData from "../../../reusebale/noData";

const MaintainanceSection = ({
  maintenanceRequest,
  onViewDetails,
  onViewCreate,
}) => {
  //Maintenant Request is in this parameter "maintenanceRequest"
  const { maintainances } = db;
  const [data, setData] = useState([]);

  const [sortConfig, setSortConfig] = useState({
    key: "lease",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  return (
    <div className="bg-white rounded-lg mt-5">
      <div className="w-[95%] mx-auto py-6">
        <div className="flex">
          <div className="w-[65%]">
            <ParagraphTag
              content="Maintenance Request"
              classes="text-darkColor font-popinsSemiBold text-lg"
            />
          </div>
          {/* <div className="w-[35%] flex justify-end">
            <button className="w-[48%] bg-themeColor rounded-md flex items-center justify-center py-2" onClick={onViewCreate}>
              <Plus color="#FFFFFF" size={15} />
              <ParagraphTag
                content="Create New"
                classes="text-white font-popinsSemiBold text-xs pl-2"
              />
            </button>
          </div> */}
        </div>
        <div className="w-full mt-5 overflow-x-auto h-[300px] panelScroll">
          <table className="w-full ">
            <thead className="sticky top-0 bg-white">
              <tr className="border-b border-black/20">
                <th className="w-[25%] text-left pb-2">
                  <span className="flex">
                    <ParagraphTag
                      content={maintainances.thead1}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "lease" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("lease")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[12%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={maintainances.thead2}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "status" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("status")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[22%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={maintainances.thead3}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "related" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("related")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("related")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("related")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[15%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={maintainances.thead7}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                    />
                    {sortConfig.key === "date" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("date")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("date")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("date")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[13%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={maintainances.thead4}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                    />
                    {sortConfig.key === "date" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("date")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("date")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("date")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[13%] text-right pr-4 pb-2">
                  <ParagraphTag
                    content={maintainances.thead6}
                    classes="text-darkColor/40 font-popinsSemiBold text-xs"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {maintenanceRequest.map((item) => (
                <tr
                  key={item?._id}
                  className="hover:bg-gray-100 rounded-md cursor-pointer"
                >
                  <td className="py-2">
                    <div className="flex items-center justify-between">
                      <div className="w-[18%] rounded-full">
                        <ImageTag
                          path="/assets/daashboard/setting-icon.png"
                          classes="w-full"
                          altText="logo"
                        />
                      </div>
                      <div className="w-[78%] cursor-pointer">
                        <ParagraphTag
                          content={`MAIN-${item?._id?.slice(0, 10)}`}
                          classes="text-darkColor/50 font-popinsMedium text-xs"
                        />
                        <ParagraphTag
                          content={`${item?.requestCategory?.category} `}
                          classes="text-darkColor font-popinsSemiBold text-sm"
                        />
                      </div>
                    </div>
                  </td>
                  <td className="py-2 font-popinsMedium text-darkColor text-sm">
                    <div className="flex justify-center items-center">
                      <span>
                        {(() => {
                          switch (item.status) {
                            case "Active":
                              return <CircleAlert color="#E43B3B" size={20} />;
                            case "Parts Ordered":
                              return <Clock4 color={"#FF814E"} size={20} />;
                            case "Completed":
                              return <CircleCheck color="#01CB78" size={20} />;
                            default:
                              break;
                          }
                        })()}
                      </span>
                      <span
                        className={`pl-2 ${
                          item.status === "Parts Ordered"
                            ? "text-themeColor"
                            : item.status === "In Progress"
                            ? "text-lightgray"
                            : "text-[#01CB78]"
                        }`}
                      >
                        {item.status}
                      </span>
                    </div>
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    <div className="flex flex-col">
                      <span className="text-darkColor/50 text-xs">
                        {`PROP-${item?.propertyId?.slice(0, 10)}`}
                      </span>
                      <span className="text-sm text-darkColor">
                        {item?.propertyInfo?.propertyName}
                      </span>
                    </div>
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    {item.unitInfo.unitName}
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    {moment(item?.dueDate).format("DD/MM/YYYY")}
                  </td>
                  <td className="pt-5 2xl:pt-4 px-4 flex justify-end items-center h-full mr-3">
                    <div
                      onClick={() => {
                        onViewDetails(item);
                      }}
                      className="flex justify-center items-center border border-themeColor w-7 h-7 rounded-md cursor-pointer"
                    >
                      <ArrowRight color="#6F6F6F" size={18} strokeWidth={2} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {maintenanceRequest.length === 0 && (
            <div className="w-full mt-5">
              <NoData />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaintainanceSection;
