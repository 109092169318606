import React, { useState } from "react";
import HomeSection from "../home/home";
import LeasesSection from "../leases/leases";
import MaintainanceSection from "../maintenance/maintainance";
import PropertySection from "../properties/properties";
import UnitsSection from "../units/units";
import TenantsSection from "../tenants/tenants";
import ReportsSection from "../reports/reports";
import HelpSection from "../help-tutorial/help";
import LogoutSection from "../logout";
import BroadCast from "../broadcast/broadcastMain";
import Notification from "../notification/main";
import ViewProfile from "../view-profile/profile";
import DetailMain from "../maintenance/maintanance-detail/detailMain";
import MainSection from "../properties/property-detail/main";
import DetailLeaseMain from "../leases/leaseDetail/main";
import PersonalInfo from "../profile-complete/personal-information/personal-info";
import CompanyInfo from "../profile-complete/company-information/company-info";
import MainTenantSection from "../tenants/tenantsDetail/main";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as LeaseDataAction from "../../../store/actions/lease/LeaseAction"
import CreateMain from "../leases/createProperty/createMain"

const RightSection = ({ activeComponent, setActiveComponent }) => {
  const navigate = useNavigate();
  const [recordId, setRecordId] = useState();
  const [propertyId, setPropertyId] = useState();
  const [showDetail, setShowDetail] = useState("");
  const [recordLeaseId, setLeaseRecordId] = useState();
  const [recordMainId, setRecordMainId] = useState();
  const [seletedTenant, setSelectedTenant] = useState();

  const dispatch = useDispatch();

  const toggleDetailProperty = (id) => {
    setPropertyId(id);
    setActiveComponent("PropertyDetails"); // State toggle karein
  };

  const toggleTenantDetail = (tenant) => {
    setSelectedTenant(tenant);
    setActiveComponent("tenantdetail");
  };

  // Function to show the Notification component
  const handleShowNotification = () => {
    setActiveComponent("Notifications"); // Set activeComponent to "Notification"
  };

  const onHandleLease = () => {
    setActiveComponent("Leases"); // Set activeComponent to "Notification"
  };

  const toggleDetailMaintain = () => {
    setActiveComponent("MaintainanceDetails");
  };

  const handleShowProperty = () => {
    setActiveComponent("Properties");
  };

  const toggleCreate = () => {
    setActiveComponent("createLease");
  };

  // Function to show the Home component
  const handleShowHome = () => {
    setActiveComponent("Home"); // Set activeComponent to "Home"
  };

  const handleShowMaintainance = () => {
    setActiveComponent("Maintenance");
  };

  const handleShowProfiles = () => {
    setActiveComponent("Profile");
  };

  const handleShowProfile = (item) => {
    if (item === "Change Password") {
      navigate("/reset-password");
    } else {
      setActiveComponent(item);
    }
  };

  const toggleLeaseDetail = (status, id) => {
    setShowDetail(status);
    setLeaseRecordId(id);
    setActiveComponent("detailLease"); // State toggle karein
  };

  const onHandleTenantList = () => {
    setActiveComponent("Tenants");
  };

  // If the activeComponent is "Notification", show the Notification component
  if (activeComponent === "Notifications") {
    return <Notification onBack={handleShowHome} />;
  }

  // Show the HomeSection component if activeComponent is "Home"
  if (activeComponent === "Home") {
    return (
      <HomeSection
        onNotificationClick={handleShowNotification}
        onMaintainancePage={handleShowMaintainance}
        onViewDetailsMaintain={(item) => {
          setRecordId(item);
          toggleDetailMaintain(item);
        }}
        onViewDetailProperty={(id) => {
          toggleDetailProperty(id);
        }}
        onViewPropertyPage={handleShowProperty}
        onDetailsTenant={toggleTenantDetail}
        onTenantList={onHandleTenantList}
      />
    );
  }

  // Handle other sections
  if (activeComponent === "Tenants")
    return (
      <TenantsSection
        toggleMaintainanceTable={handleShowMaintainance}
        onTriggerCard={(item) => {
          handleShowProfile(item);
        }}
        onBack={handleShowHome}
      />
    );
  if (activeComponent === "Properties")
    return <PropertySection toggleMaintainanceTable={handleShowMaintainance} onBackHome={handleShowHome}/>;
  if (activeComponent === "Units") return <UnitsSection />;
  if (activeComponent === "Maintenance") return <MaintainanceSection />;
  if (activeComponent === "Leases")
    return (
      <LeasesSection
        toggleMaintainanceTable={handleShowMaintainance}
        onTriggerCard={(item) => {
          handleShowProfile(item);
        }}
      />
    );
  if (activeComponent === "Reports") return <ReportsSection />;
  if (activeComponent === "Broadcasts")
    return <BroadCast onBackHome={handleShowHome} />;
  if (activeComponent === "Help") return <HelpSection />;
  if (activeComponent === "Logout") return <LogoutSection />;
  if (activeComponent === "General Info")
    return <PersonalInfo clases="mt-5" onBack={handleShowProfiles} />;
  if (activeComponent === "Edit Company info")
    return <CompanyInfo onBack={handleShowProfiles} />;
  if (activeComponent === "tenantdetail")
    return (
      <MainTenantSection
        seletedTenant={seletedTenant}
        onBack={handleShowHome}
        onViewMaintainDetails={(item) => {
          setRecordId(item);
          toggleDetailMaintain(item);
        }}
        onAction={(item) => {
          handleShowProfile(item);
        }}
        toggleLeaseDetail={toggleLeaseDetail}
        onTriggerCard={handleShowProfile}
      />
    );
  if (activeComponent === "Profile")
    return (
      <ViewProfile
        onTriggerCard={(item) => {
          handleShowProfile(item);
        }}
      />
    );
  if (activeComponent === "MaintainanceDetails")
    return <DetailMain recordId={recordId._id} onBack={handleShowHome} />;
  if (activeComponent === "createLease")
    return  <CreateMain onBack={handleShowHome} />;
  // if (activeComponent === "MaintainanceDetails")
  //   return <DetailMain recordId={recordId._id} onBack={handleShowHome} />;
  if (activeComponent === "detailLease")
    return (
      <DetailLeaseMain
        onBack={handleShowHome}
        showDetailStatus={showDetail}
        recordId={recordLeaseId}
        onViewDetailsMaintain={(item) => {
          setRecordId(item);
          toggleDetailMaintain(item);
        }}
        onEditLease={(leaseDetails) => {
          toggleCreate();
          dispatch(LeaseDataAction.LeaseDetailAction(leaseDetails));
        }}
        onMaintainancePage={handleShowMaintainance}
      />
    );
  if (activeComponent === "PropertyDetails")
    return (
      <MainSection
        propertyId={propertyId}
        onBack={handleShowHome}
        onViewDetailsMaintain={(item) => {
          setRecordId(item);
          toggleDetailMaintain(item);
        }}
        onMaintainancePage={handleShowMaintainance}
        toggleLeaseDetail={toggleLeaseDetail}
        onCreateLease={onHandleLease}
      />
    );

  return null; // Default case (if no activeComponent matches)
};

export default RightSection;
